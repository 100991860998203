/*
Media Query helper to request current media query in js
global css class ".media-queries-to-js" contains breakpoint information
stored in the font-family property
example: font-family: "/xs:0/sm:576px/md:655px/lg:992px/xl:1200px/xxl:1441px/xxxl:1500px"
 */
let mediaQueries = null

export const getBreakpointsFromCss = () => {
    if (mediaQueries === null) {
        mediaQueries = []
        const element = document.createElement('div')
        element.classList.add('media-queries-to-js')
        document.documentElement.append(element)
        getComputedStyle(element)
            .fontFamily.split('/')
            .map((breakpoint) => {
                const breakpointSplit = breakpoint.split(':')
                if (breakpointSplit.length === 2) {
                    breakpointSplit[1] = parseInt(breakpointSplit[1].replace('px', ''))
                    mediaQueries[breakpointSplit[0]] = breakpointSplit[1]
                    mediaQueries.push(breakpointSplit)
                }
            })
    }
}

export const mediaBreakpointUp = (name) => {
    getBreakpointsFromCss()
    return window.matchMedia(`(min-width: ${mediaQueries[name]}px)`).matches
}

export const mediaBreakpointDown = (name) => {
    getBreakpointsFromCss()
    return window.matchMedia(`(max-width: ${mediaQueries[name] - 0.2}px)`).matches
}

export const mediaCurrentBreakpoint = () => {
    getBreakpointsFromCss()
    for (let i = 0; i < mediaQueries.length; i++) {
        if (window.matchMedia(`(max-width: ${mediaQueries[i][1] - 0.2}px)`).matches) {
            return mediaQueries[i - 1][0]
        }
    }
    return mediaQueries[mediaQueries.length - 1][0]
}
