import store from '../store'

export const initTrackingService = () => {
    store.watch(
        (state) => state.pageScrollCounter,
        (pageScrollCount) => {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
                event: 'pageScrollCount',
                count: pageScrollCount,
            })
        }
    )
}
