<template>
  <div
    class="subpage-navigation"
    :class="{
      inline: inline,
      container: !inline,
      showOnMobile: showOnMobile,
    }"
    :style="style"
  >
    <div :class="{ row: !inline }">
      <div
        :class="{
          'col-12 offset-0 col-xl-10 offset-xl-1': !inline,
        }"
      >
        <ArrowButton class="btn prev" v-if="prev" :prev="true" v-on:click="onClick"></ArrowButton>
        <ArrowButton class="btn next" v-if="next" v-on:click="onClick"></ArrowButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ArrowButton from '@/components/buttons/ArrowButton'
import gsap from 'gsap'

export default {
  name: 'SubpageNavigation',
  components: {
    ArrowButton,
  },
  props: {
    next: String,
    prev: String,
    inline: {
      type: Boolean,
      default: false,
    },
    skipVideo: {
      type: Boolean,
      default: true,
    },
    showOnMobile: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['contentMediaPosition']),
    style() {
      return this.contentMediaPosition ? `top:${this.contentMediaPosition.top + this.contentMediaPosition.height / 2}px` : ''
    },
  },
  methods: {
    onClick(prev) {
      const to = prev ? this.prev : this.next
      this.$store.commit('skipVideo', this.skipVideo)
      this.$router.push({
        path: `/${this.$route.params.lang}/${to}/`,
        query: this.$route.query,
      })
    },
  },
  mounted() {
    if (this.next) {
      const next = this.$el.getElementsByClassName('next')
      gsap.set(next, {
        opacity: 0,
      })

      gsap.to(next, {
        delay: 1,
        opacity: 1,
      })

      gsap.from(next, {
        delay: 1,
        x: 30,
        clearProps: true,
      })
    }
    if (this.prev) {
      const prev = this.$el.getElementsByClassName('prev')
      gsap.set(prev, {
        opacity: 0,
      })

      gsap.to(prev, {
        delay: 1,
        opacity: 1,
      })

      gsap.from(prev, {
        delay: 1,
        x: -30,
        clearProps: true,
      })
    }
  },
}
</script>

<style scoped lang="scss">
$height: 78px;

.subpage-navigation {
  position: absolute;
  z-index: $zindex-subpagenavigation;
  width: 100%;
  top: 50%;
  transform: translateY(calc(-50% + var(--wmf-content-offset-y)));

  @include media-breakpoint-up(lg) {
    transform: translateY(-50%);
  }

  @include media-breakpoint-only(lg) {
    position: relative;
    top: auto;
    transform: none;
    bottom: $height;
    height: 0;
  }

  @include media-breakpoint-down(lg) {
    padding: 0;
  }

  .row {
    //min-height: $height;
    position: relative;
    width: auto;

    & > div {
      position: relative;
    }
  }

  .btn {
    position: absolute;
    transform: translateY(-50%);
  }

  .prev {
    left: 0;
  }

  .next {
    right: 0;
  }

  &.inline {
    position: absolute;
    width: calc(100% - 100px);
    left: 50px;
    top: 5px !important;

    transform: none;

    display: none;

    .prev {
      left: 0;
      transform: translateX(-100%);
    }

    .next {
      right: 0;
      transform: translateX(100%);
    }

    @include media-breakpoint-up(md) {
      top: 8px !important;
      display: block;
    }

    @include media-breakpoint-up(lg) {
      width: 100%;
      top: 11px !important;
      left: 0;
    }
  }

  &.showOnMobile {
    display: block;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }
}
</style>
