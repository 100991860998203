<template>
  <component
    :is="tag"
    @click.native="onClick"
    class="btn p-0 text-uppercase font-inter fw-semi-bold d-inline-flex align-items-center letter-spacing-wide"
    :href="tag === 'router-link' ? internalLink : href"
    :to="tag === 'router-link' ? internalLink : ''"
    :target="target"
  >
    <div class="button__wrapper" @mouseenter="onMouseOver" @mouseleave="onMouseOut">
      <span class="me-20px" ref="label">
        <slot></slot>
      </span>
      <IconArrowInstagram v-if="this.icon === 'instagram'" class="icon instagram" />
      <IconArrowRightLarge v-else class="icon" />
    </div>
  </component>
</template>

<script>
import IconArrowRightLarge from '@/components/icons/IconArrowRightLarge'
import IconArrowInstagram from '@/components/icons/IconArrowInstagram'
import linkMixin from '@/components/mixins/linkMixin'
import gsap from 'gsap'

export default {
  components: {
    IconArrowRightLarge,
    IconArrowInstagram,
  },
  mixins: [linkMixin],
  props: {
    href: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    enableBackButton: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onMouseOver() {
      const icon = this.$el.getElementsByTagName('svg')

      if (icon) {
        const line = icon[0].getElementsByTagName('line')[0]
        gsap.to(line, {
          x: 463,
          attr: { x1: '100' },
        })

        const circle = icon[0].getElementsByTagName('circle')[0]
        gsap.to(circle, {
          ease: 'Expo.easeOut',
          duration: 1,
          attr: { r: '48' },
        })

        gsap.to(this.$refs.label, {
          x: 20,
        })
      }
    },
    onMouseOut() {
      const icon = this.$el.getElementsByTagName('svg')

      if (icon) {
        const line = icon[0].getElementsByTagName('line')[0]
        gsap.to(line, {
          x: 451,
          attr: { x1: '112.993' },
        })

        const circle = icon[0].getElementsByTagName('circle')[0]
        gsap.to(circle, {
          attr: { r: '45.321' },
        })

        gsap.to(this.$refs.label, {
          x: 0,
        })
      }
    },
    onClick(event) {
      if (!this.enableBackButton) {
        return true
      }
      // show back button after a click on rich text link if target is on detail level
      if (event.currentTarget.getAttribute('href').length > 2) {
        this.$store.commit('showBackButton', true)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.btn {
  display: flex;
  border-radius: unset;
}

.button__wrapper {
  display: flex;
  align-items: center;
  text-align: left;
}

.icon {
  font-size: 6.18em;

  &.instagram {
    @include media-breakpoint-down(lg) {
      font-size: 5em;
    }
  }

  //@include media-breakpoint-up(lg) {
  //  font-size: 8.36em;
  //}
}

svg {
  overflow: visible;
}

span {
  display: inline-block;
}
</style>
