<template>
  <svg
    :width="widthEm"
    :height="heightEm"
    :viewBox="`0 0 ${width} ${height}`"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="buffering"
    class="icon-buffering"
  >
    <g>
      <circle cx="25" cy="25" r="25" fill="none" />
      <path
        d="M9.57,18.48a16.79,16.79,0,0,1,22-8.91,16.79,16.79,0,0,1,8.91,22,16.79,16.79,0,0,1-21.95,8.91,16.84,16.84,0,0,1-8.91-8.91"
        fill="none"
        stroke="#434343"
        stroke-linecap="round"
        stroke-width="1.5"
      />
    </g>
    <g>
      <circle cx="25" cy="25" r="25" fill="none" />
      <path
        d="M18.48,9.57a16.76,16.76,0,0,1,22,22,16.79,16.79,0,0,1-21.95,8.91,16.84,16.84,0,0,1-8.91-8.91"
        fill="none"
        stroke="#fff"
        stroke-linecap="round"
        stroke-width="4"
      />
    </g>
    <g>
      <circle cx="25" cy="25" r="25" fill="none" />
      <path d="M3.64,16a23.2,23.2,0,0,1,42.72,0" fill="none" stroke="#434343" stroke-linecap="round" stroke-width="2" />
    </g>
    <g>
      <circle cx="25" cy="25" r="25" fill="none" />
      <path d="M1.82,25a23.19,23.19,0,0,1,44.54-9" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="0.75" />
    </g>
  </svg>
</template>

<script>
import gsap from 'gsap'
import IconBase from '@/components/icons/IconBase'

export default {
  name: 'IconBuffering',
  extends: IconBase,
  props: {
    width: {
      type: [Number],
      default: 50,
    },
    height: {
      type: [Number],
      default: 50,
    },
  },
  data() {
    return {
      length1: 105.19600677490234,
      length2: 145.66477966308594,
    }
  },
  methods: {
    animateRing(ring) {
      //const rotation = Math.random() * 360
      const duration = 0.5 + Math.random() * 1.5

      gsap
        .to(ring, {
          duration: duration,
          rotation: '+=' + (180 + Math.random() * 180),
          transformOrigin: 'center center',
          ease: 'Linear.easeInOut',
        })
        .then(() => {
          this.animateRing(ring)
        })
    },
  },
  beforeDestroy() {
    const rings = this.$el.getElementsByTagName('g')
    Array.prototype.forEach.call(rings, (ring) => {
      gsap.killTweensOf(ring)
    })
  },
  mounted() {
    this.$nextTick(() => {
      const rings = this.$el.getElementsByTagName('g')

      Array.prototype.forEach.call(rings, (ring) => {
        const rotation = Math.random() * 360

        gsap.set(ring, {
          rotation: rotation,
          scale: 1,
          transformOrigin: 'center center',
        })

        this.animateRing(ring)
      })
    })
  },
}
</script>

<style scoped lang="scss">
.icon-buffering {
  position: fixed;

  top: 50%;
  left: 50%;

  transform: translateX(-50%) translateY(-50%);

  width: 50px;
  height: 50px;

  z-index: 100;
}
</style>
