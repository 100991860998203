export default {
    data() {
        return {
            topReached: true,
            bottomReached: false,
            scrollDataConfig: {
                debounceMS: 5,
            },
            scrollData: {
                touch: {
                    current: undefined,
                    last: undefined,
                },
                startTime: 0,
                startScrollY: 0,
                delta: 0,
                value: 0,
                time: 0,
                timeout: undefined,
                isTrackpad: false,
            },
        }
    },
    computed: {
        scrollValue() {
            return this.scrollData.value
        },
    },
    methods: {
        onScroll(e) {
            const delta = e.isTrusted ? e.wheelDelta : e.deltaY

            let currentScroll = Math.floor(this.$el.scrollTop)
            let maxScroll = this.$el.scrollHeight - 1
            let wrapperHeight = this.$el.clientHeight

            if (Date.now() - this.mountTimestamp > 1750) {
                if (currentScroll === 0 && delta > 0 && this.prev) {
                    clearTimeout(this.addEventListenerTimeout)
                    this.removeEventListener()
                    if (this.isScrollable && this.topReached === false) {
                        this.topReached = true
                        this.addEventListenerTimeout = setTimeout(() => {
                            this.addEventListener()
                        }, 500)
                    } else {
                        this.clearTouchListener()
                        this.changePage(this.prev, true)
                    }
                } else if (Math.round(currentScroll + wrapperHeight) >= maxScroll && delta < 0 && this.next) {
                    clearTimeout(this.addEventListenerTimeout)
                    this.removeEventListener()
                    if (this.isScrollable && this.bottomReached === false) {
                        this.bottomReached = true
                        this.addEventListenerTimeout = setTimeout(() => {
                            this.addEventListener()
                        }, 500)
                    } else {
                        this.clearTouchListener()
                        this.changePage(this.next, false)
                    }
                } else if (maxScroll > window.innerHeight) {
                    // pause scroll events listening if content is scrollable
                    clearTimeout(this.addEventListenerTimeout)
                    this.removeEventListener()
                    this.addEventListenerTimeout = setTimeout(() => {
                        this.addEventListener()
                    }, 1000)
                    this.topReached = false
                    this.bottomReached = false
                }
            }
        },
        addEventListener() {
            this.$el.addEventListener('wheel', this.onScroll, { passive: true })
            this.$el.addEventListener('touchstart', this.onTouchStart, {
                passive: true,
            })
        },
        removeEventListener() {
            this.$el.removeEventListener('wheel', this.onScroll)
            this.$el.removeEventListener('touchstart', this.onTouchStart)
        },
        onTouchStart(e) {
            this.clearTouchListener()
            this.scrollData.touch.startScrollY = this.$el.scrollTop
            this.scrollData.touch.last = e.touches[0].clientY
            this.scrollData.touch.currentX = e.touches[0].clientX

            this.$el.addEventListener('touchend', this.onTouchEnd, {
                passive: true,
            })
            this.$el.addEventListener('touchmove', this.onTouchMove, {
                passive: true,
            })
        },
        onTouchEnd() {
            this.clearTouchListener()
            this.$el.addEventListener('touchstart', this.onTouchStart, {
                passive: true,
            })

            this.scrollData.touch.last = undefined
            this.scrollData.touch.current = undefined
            this.scrollData.touch.currentX = undefined
        },
        clearTouchListener() {
            this.$el.removeEventListener('touchend', this.onTouchEnd)
            this.$el.removeEventListener('touchmove', this.onTouchMove)
            this.$el.removeEventListener('touchstart', this.onTouchStart)
        },
        onTouchMove(e) {
            this.scrollData.touch.current = e.touches[0].clientY

            const lastY = this.scrollData.touch.last
            const lastX = this.scrollData.touch.currentX

            const distX = Math.abs(lastX - e.touches[0].clientX)
            const distY = Math.abs(lastY - e.touches[0].clientY)

            if (distX > distY) return

            const fakeDelta = (this.scrollData.touch.current - this.scrollData.touch.last) * 0.0125
            this.onScroll({
                deltaY: fakeDelta,
            })
        },
    },
    destroy() {
        this.clearTouchListener()
        this.removeEventListener()
    },
    mounted() {
        this.$nextTick(() => {
            this.$nextTick(() => {
                this.isScrollable = this.$el.scrollHeight !== this.$el.clientHeight
                this.mountTimestamp = Date.now()
                this.addEventListener()
            })
        })
    },
}
