<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title fw-medium">
        <slot name="card-header"></slot>
      </h4>
    </div>
    <div class="card-body fw-light">
      <p class="card-text">
        <slot name="card-body"></slot>
      </p>
    </div>
    <div class="card-footer">
      <slot name="card-footer"></slot>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.card {
  padding: 40px 60px;
}

.card-footer {
  margin-top: 20px;
}
</style>
