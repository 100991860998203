var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"animation",class:{
    'animation--full-video': _vm.fullVideo,
    'animation--skipped': _vm.skip || _vm.contentVisible,
    'is-tablet': _vm.isTablet,
  },on:{"click":_vm.skipAnimation}},[(_vm.video && !_vm.videoDone)?_c('div',{staticClass:"video",style:({ top: _vm.videoPosition })},[_c('video',{ref:"video",attrs:{"autoplay":"","playsinline":"","muted":"","preload":"auto"},domProps:{"muted":true},on:{"~ended":function($event){return _vm.onVideoEnded.apply(null, arguments)},"canplaythrough":_vm.onVideoReady}},[_c('source',{attrs:{"src":_vm.videoSrc,"type":"video/mp4"}})]),(_vm.video.poster)?_c('BaseImage',_vm._b({ref:"poster",staticClass:"poster",on:{"load":_vm.onPosterLoaded}},'BaseImage',_vm.video.poster,false)):_vm._e(),_c('div',{ref:"buffering",staticClass:"buffering"},[_c('IconBuffering')],1)],1):_vm._e(),_vm._l((_vm.content),function(contentItem,index){
  var _obj, _obj$1, _obj$2;
return _c('div',{key:("content-animation-item-" + index),ref:"item",refInFor:true,staticClass:"wrapper",class:[
      contentItem.copy || _vm.contentHasTeaser(contentItem) ? 'has-copy' : '',
      contentItem.slider ? 'clear-margin' : '',
      contentItem.align,
      contentItem.position,
      _vm.showEditorialContent(index) ? 'has-editorial-content' : '',
      contentItem['column-direction'],
      {
        'wrapper--flex-start': _vm.showEditorialContent(index) || _vm.getCollapseContent(contentItem),
      } ]},[(_vm.next && (_vm.videoDone || _vm.isMobile))?_c('ScrollButton',{staticClass:"scrollButton",attrs:{"label":_vm.$t('scroll')},nativeOn:{"click":function($event){return _vm.onScrollButtonClick.apply(null, arguments)}}}):_vm._e(),_c('div',{staticClass:"intro-content"},[(contentItem.outline || contentItem.chapter)?_c('div',{staticClass:"chapter-wrapper container-fluid",class:( _obj = {}, _obj['chapter--flex' + _vm.getChapterFlex(contentItem)] = _vm.getChapterFlex(contentItem), _obj['outline--lines' + _vm.getOutlineLines(contentItem)] = _vm.getOutlineLines(contentItem), _obj )},[_c('div',{staticClass:"row"},[(contentItem.outline)?_c('OutlineText',{ref:"chapter_outline",refInFor:true,staticClass:"outline"},[_vm._v(" "+_vm._s(contentItem.outline)+" ")]):_vm._e(),(contentItem.chapter)?_c('h2',{ref:"chapter_headline",refInFor:true,staticClass:"indent chapter",class:( _obj$1 = {}, _obj$1['chapter--align-' + contentItem['chapter-align']] = contentItem['chapter-align'], _obj$1['indent--' + contentItem['chapter-indent']] = contentItem['chapter-indent'], _obj$1 ),domProps:{"innerHTML":_vm._s(contentItem.chapter)}}):_vm._e()],1)]):_vm._e(),_c('div',{ref:"media",refInFor:true,staticClass:"content-media",class:{
          'content-media--hidden': !_vm.mediaVisible,
          'content-media--full': contentItem.slider,
        }},[(contentItem['background'])?_c('div',{staticClass:"background",class:{
            'content-media--background': contentItem['background'],
          },style:(_vm.backgroundStyles(contentItem))}):_vm._e(),(contentItem.imageMobile && _vm.isMobile)?[_c('BaseImage',_vm._b({staticClass:"image"},'BaseImage',contentItem.imageMobile,false))]:[(contentItem.image && contentItem.image.src)?_c('BaseImage',_vm._b({staticClass:"image"},'BaseImage',contentItem.image,false)):_vm._e()]],2),(contentItem.slider)?_c('div',{staticClass:"container",staticStyle:{"width":"100vw"}},[_c('div',{staticClass:"product-slider__container row"},[_c('ProductSlider',{staticClass:"col-10 offset-1 col-lg-8 offset-lg-2 col-xxl-10 offset-xxl-1",attrs:{"contents":_vm.sliderContents(contentItem.slider.contents),"mouseEnabled":_vm.sliderEnabled,"visible":_vm.contentVisible},on:{"change":_vm.onProductSliderChange}}),_c('div',{staticClass:"product-slider__info-container col-12 offset-0 col-lg-10 offset-lg-1"},[(contentItem.copy)?_c('p',{staticClass:"copy copy--regular",domProps:{"innerHTML":_vm._s(contentItem.copy)}}):_vm._e(),(_vm.sliderContents(contentItem.slider.contents)[_vm.productSliderIndex] && _vm.contentVisible)?_c('ProductSliderInfo',{attrs:{"content":_vm.sliderContents(contentItem.slider.contents)[_vm.productSliderIndex].detail}}):_vm._e()],1)],1)]):_vm._e(),_c('div',{staticClass:"container-fluid content-container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"indent content",class:( _obj$2 = {}, _obj$2['indent--' + contentItem['content-indent']] = Number.isInteger(contentItem['content-indent']), _obj$2['content--narrow'] =  contentItem.slider, _obj$2['content--has-navigation'] =  _vm.subpages && _vm.navigation === 'inline', _obj$2['content-only-mobile'] =  contentItem['content-only-mobile'], _obj$2 ),style:({
              transform: 'translateY(' + contentItem['content-position'] + 'vh)',
              textAlign: contentItem['content-textalign'],
            })},[(contentItem.headline)?_c(index < _vm.content.length - 1 ? 'h3' : 'h1',{tag:"component",staticClass:"headline headline--regular"},_vm._l((contentItem.headline),function(textElement,index){return _c('span',{key:("text-" + textElement + "-" + index),domProps:{"innerHTML":_vm._s(textElement)}})}),0):_vm._e(),(_vm.subpages && _vm.navigation === 'inline')?_c('SubpageNavigation',_vm._b({attrs:{"inline":_vm.navigation === 'inline'}},'SubpageNavigation',_vm.subpages,false)):_vm._e(),(contentItem.copy)?_c('p',{staticClass:"copy copy--regular",domProps:{"innerHTML":_vm._s(contentItem.copy)}}):_vm._e(),(_vm.contentHasTeaser(contentItem))?_c('div',{staticClass:"teaser-wrapper"},[_vm._l(([contentItem['teaser'], contentItem['teaser-2']]),function(teaser,index){return [(teaser && teaser.headline && teaser.image)?_c('div',{key:("teaser-" + index),staticClass:"teaser"},[_c('ImageTeaser',_vm._b({staticClass:"image-teaser"},'ImageTeaser',teaser,false))],1):(teaser && teaser.headline)?_c('div',{key:("teaser-" + index),staticClass:"teaser"},[_c('TextTeaser',_vm._b({staticClass:"text-teaser"},'TextTeaser',teaser,false))],1):_vm._e()]})],2):_vm._e(),(contentItem.cta)?_c('MoreButton',_vm._b({staticClass:"cta"},'MoreButton',contentItem.cta,false),[_vm._v(" "+_vm._s(contentItem.cta.label)+" ")]):_vm._e()],1)])])]),(_vm.showEditorialContent(index))?_c('div',{ref:"editorial",refInFor:true,staticClass:"editorial-content"},_vm._l((_vm.editorial_content),function(module,index){return _c(module.type,_vm._b({key:("module-" + (module.type) + "-" + index),tag:"component"},'component',module.props,false))}),1):_vm._e()],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }