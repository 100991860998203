<template>
  <footer class="footer">
    <div class="meta" v-if="footerMeta">
      <div class="footer-item" v-bind="footerMeta" v-for="(footerItem, index) in footerMeta" :key="index">
        <div class="title">
          {{ footerItem.label }}
        </div>

        <div class="content" v-for="(item, index) in footerItem.content" :key="index">
          <template v-if="item.type === 'text'">
            <RichText class="copy" v-if="item.copy" :html="item.copy" />
          </template>

          <template v-if="item.type === 'link'">
            <div class="link-item">
              <base-link :to="`${item.href}`" :target="item.href.indexOf('http') >= 0 ? '_blank' : ''" :title="item.label" :enableBackButton="false">
                {{ item.label }}
              </base-link>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="social">
      <SocialMedia />
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'
import RichText from '@/components/RichText'
import SocialMedia from '@/components/SocialMedia'
import BaseLink from './BaseLink'

export default {
  name: 'Footer',
  components: {
    BaseLink,
    SocialMedia,
    RichText,
  },
  computed: {
    ...mapGetters(['footerMeta']),
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.footer {
  position: relative;
  background-color: $black;
  color: white;
  display: flex;
  min-height: var(--wmf-footer-height);
  padding: $footer-mobile-padding;
  flex-direction: column;

  a {
    padding: 0 25px;
    text-decoration: none;
    font-weight: $font-weight-semi-bold;
    text-transform: uppercase;
    font-size: $font-size-sm;
  }

  @include media-breakpoint-up(lg) {
    justify-content: space-around;

    .meta {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-around;
    }

    .footer-item {
      width: 50%;
    }
  }

  @include media-breakpoint-up(xl) {
    padding: $footer-large-padding;
  }

  @include media-breakpoint-up(xxl) {
    flex-direction: row;

    .meta {
      width: 100%;
    }

    .footer-item {
      width: 25%;
    }
  }
}

.title {
  text-transform: uppercase;
  font-family: var(--wmf-font-wmfrotis);
  font-weight: $font-weight-semi-bold;
  font-size: $font-size-sm;
}

.footer-item {
  padding-right: 30px;
}

.footer-item {
  width: 290px;
  margin-top: $spacing-detail-m;
}

.content {
  margin-top: $spacing-detail-xs;
}

.copy,
.link-item a {
  font-family: var(--wmf-font-inter);
  font-weight: $font-weight-light;
  font-size: 15px;
  text-transform: none;
  padding: 0;
}

.link-item a {
  display: inline-block;
  line-height: 1.5;
}

.social {
  display: flex;
  justify-content: left;
  font-size: 40px;
  line-height: 0;
}
</style>
