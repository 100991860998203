<template>
  <button class="btn d-inline-block" v-on:click="$emit('click', prev)">
    <Icon class="icon" :name="iconName" />
  </button>
</template>

<script>
import Icon from '@/components/Icon'

export default {
  name: 'ArrowButton',
  components: {
    Icon,
  },
  props: {
    prev: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconName() {
      return this.prev ? 'ArrowPrev' : 'ArrowNext'
    },
  },
}
</script>

<style scoped lang="scss">
button {
  &:hover,
  &:focus {
    color: $gray-400;
  }
}

.icon {
  transition: color;
  font-size: 36px;
}
</style>
