<template>
  <div class="container-fluid">
    <div class="cta row">
      <div class="offset-0 col-12 col-lg-6 offset-lg-2">
        <h2 class="headline headline--regular" v-if="headline" v-html="headline"></h2>

        <p class="copy copy--regular" v-if="copy" v-html="copy"></p>

        <MoreButton class="btn" v-if="cta" v-bind="cta">
          {{ cta.label }}
        </MoreButton>
      </div>
    </div>
  </div>
</template>

<script>
import MoreButton from '@/components/buttons/MoreButton'

export default {
  name: 'Cta',
  components: { MoreButton },
  props: {
    headline: String,
    copy: String,
    cta: Object,
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    getButtonTag(item) {
      return item.cta && /http/.test(item.cta.href) ? 'a' : 'router-link'
    },
  },
}
</script>

<style scoped lang="scss">
.cta {
  position: relative;
  margin-top: 100px;

  .copy {
    margin-bottom: 25px;
  }
}
</style>
