export default {
    computed: {
        isExternalLink() {
            return this.testExternalLink(this.href)
        },
        internalLink() {
            // leave absolute urls untouched
            if (this.href === null || this.href[0] === '/') {
                return this.href
            } else {
                const page = this.$router.getPageBySlug(this.href)
                return page.view === 'Main'
                    ? `/${this.$route.params.lang}/${this.href}`
                    : `/${this.$route.params.lang}/${this.$route.params.main}/${this.href}`
            }
        },
        tag() {
            return this.getTag(this.href)
        },
    },
    methods: {
        testExternalLink(link) {
            return /^(tel|http|intent|mailto)/.test(link)
        },
        getTag(link) {
            return this.testExternalLink(link) ? 'a' : 'router-link'
        },
    },
}
