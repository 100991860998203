import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',
    state: {
        showDebugGrid: false,
        cmsContent: {
            config: {},
            chapters: [],
            navigation: {
                models: [],
                additionalInformation: [],
            },
            locales: [],
            footer: {
                meta: [],
            },
            pages: [],
        },
        showBackButton: false,
        showBuyButton: false,
        currentChapter: '',
        skipVideo: false,
        playedVideoTransitions: [],
        bowser: null,
        contentMediaPosition: {
            top: 0,
            height: 0,
        },
        openLanguageSelection: false,
        openRetailerLayer: false,
        retailerLinks: [],
        openLightbox: false,
        lightboxImages: [],
        lightboxStartIndex: 0,
        currentBufferVideo: undefined,
        pageScrollCounter: 0,
        pageScrollSlugs: [],
        klaro: {
            consentSpotify: false,
        },
    },
    mutations: {
        setCmsContent(state, cmsContent) {
            state.cmsContent = cmsContent
        },
        showBackButton(state, value) {
            state.showBackButton = value
        },
        showBuyButton(state, value) {
            state.showBuyButton = value
        },
        setCurrentChapter(state, value) {
            state.currentChapter = value
        },
        skipVideo(state, value) {
            state.skipVideo = value
        },
        addPlayedVideoTransition(state, value) {
            state.playedVideoTransitions.push(value)
        },
        setContentMediaPosition(state, value) {
            state.contentMediaPosition = value
        },
        setBowser(state, value) {
            state.bowser = value
        },
        setOpenLanguageSelection(state, value) {
            state.openLanguageSelection = value
        },
        setRetailerLinks(state, value) {
            state.retailerLinks = value
        },
        setOpenRetailerLayer(state, value) {
            state.openRetailerLayer = value
        },
        setOpenLightbox(state, payload) {
            state.openLightbox = true
            state.lightboxImages = payload.images
            state.lightboxStartIndex = payload.startIndex
        },
        setCloseLightbox(state) {
            state.openLightbox = false
            state.lightboxImages = []
            state.lightboxStartIndex = 0
        },
        setCurrentBufferVideo(state, value) {
            state.currentBufferVideo = value
        },
        setSpotifyConsent(state, value) {
            state.klaro.consentSpotify = value
        },
        incPageScrollCounter(state, slug) {
            if (state.pageScrollSlugs.indexOf(slug) < 0) {
                state.pageScrollCounter++
                this.commit('addPageScrollSlug', slug)
            }
        },
        addPageScrollSlug(state, slug) {
            state.pageScrollSlugs.push(slug)
        },
    },
    actions: {},
    modules: {},
    getters: {
        chapters: (state) => {
            // legacy fallback to menu if chapters is not available
            return state.cmsContent.chapters ? state.cmsContent.chapters : state.cmsContent.menu
        },
        locales: (state) => {
            return state.cmsContent.locales ? state.cmsContent.locales : []
        },
        footerMeta: (state) => {
            return state.cmsContent.footer && state.cmsContent.footer.meta ? state.cmsContent.footer.meta : null
        },
        socialMediaLinks: (state) => {
            return state.cmsContent.config.socialMediaLinks
        },
        config: (state) => {
            return state.cmsContent.config
        },
        headerType: (state) => {
            // return state.cmsContent.config.header ? 'Simple' : 'Simple'
            return state.cmsContent.config.header ? state.cmsContent.config.header : 'Simple'
        },
        twicpicsDomain: (state) => {
            return state.cmsContent.config.twicpicsDomain ? state.cmsContent.config.twicpicsDomain : 'https://heaxvbgw.twic.pics'
        },
        noSupportContent: (state) => {
            return state.cmsContent.no_support
        },
        translations: (state) => {
            return state.cmsContent.config.translations
        },
        ar: (state) => {
            return state.cmsContent.config.ar
        },
        navigation: (state) => {
            return state.cmsContent.navigation
        },
        currentChapter: (state) => {
            return state.currentChapter
        },
        skipVideo: (state) => {
            return state.skipVideo
        },
        playedVideoTransitions: (state) => {
            return state.playedVideoTransitions
        },
        buyButton: (state) => {
            return state.showBuyButton
        },
        isDesktop: (state) => {
            return state.bowser.getPlatformType() === 'desktop'
        },
        isTablet: (state) => {
            return (
                state.bowser.getPlatformType() === 'tablet' ||
                (navigator.userAgent.includes('Mac') && 'ontouchend' in document && window.innerWidth >= 768)
            )
        },
        bowser: (state) => {
            return state.bowser
        },
        showDebugGrid: (state) => {
            return state.showDebugGrid
        },
        browserSupportConfig: (state) => {
            return state.cmsContent.config.browserSupport
        },
        onlineshopTeaserConfig: (state) => {
            return state.cmsContent.config.onlineshopTeaser ? state.cmsContent.config.onlineshopTeaser : { show: false }
        },
        contentMediaPosition: (state) => {
            return state.contentMediaPosition
        },
        openLanguageSelection: (state) => {
            return state.openLanguageSelection
        },
        openLightbox: (state) => {
            return state.openLightbox
        },
        lightboxImages: (state) => {
            return state.lightboxImages
        },
        lightboxStartIndex: (state) => {
            return state.lightboxStartIndex
        },
        currentBufferVideo: (state) => {
            return state.currentBufferVideo
        },
        spotifyConsent: (state) => {
            return state.klaro.consentSpotify
        },
        openRetailerLayer: (state) => {
            return state.openRetailerLayer
        },
        retailerLinks: (state) => {
            return state.retailerLinks
        },
    },
})
