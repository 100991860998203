<template>
  <component
    :is="tag"
    class="btn text-uppercase font-inter fw-semi-bold d-inline-flex align-items-center letter-spacing-wide lh-1"
    :href="tag === 'router-link' ? internalLink : href"
    :target="target"
    v-bind:class="classObject"
    :type="type"
    :to="tag === 'router-link' ? internalLink : ''"
    @click.native="onClick"
  >
    <span
      v-if="iconBefore"
      class="icon-before me-3 border h-42px w-42px rounded-circle border-white d-flex justify-content-center align-items-center"
    >
      <Icon :name="iconBefore" />
    </span>
    <span>
      <slot></slot>
    </span>

    <Icon v-if="iconAfter" :name="iconAfter" class="ms-2" />
  </component>
</template>

<script>
import Icon from '@/components/Icon'

export default {
  props: {
    big: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String,
      default: 'button',
    },
    color: {
      type: String,
      default: null,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: null,
    },
    iconAfter: {
      type: String,
      default: null,
    },
    iconBefore: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    enableBackButton: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Icon,
  },
  computed: {
    classObject: function () {
      const classes = []
      if (this.outline) classes.push(`btn-${this.outline ? 'outline-' : ''}${this.color}`)

      if (this.iconBefore) {
        classes.push('p-0')
      }

      if (this.big) {
        classes.push('big')
      }
      return classes
    },
    internalLink() {
      // leave absolute urls untouched
      if (this.href === null || this.href[0] === '/') {
        return this.href
      } else {
        const page = this.$router.getPageBySlug(this.href)
        return page.view === 'Main'
          ? `/${this.$route.params.lang}/${this.href}`
          : `/${this.$route.params.lang}/${this.$route.params.main}/${this.href}`
      }
    },
  },
  methods: {
    onClick(event) {
      if (!this.enableBackButton) {
        return true
      }
      // show back button after a click on rich text link if target is on detail level
      if (event.currentTarget.getAttribute('href').length > 2) {
        this.$store.commit('showBackButton', true)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.btn {
  transition: color 200ms;

  .icon-before {
    flex-shrink: 0;
    transition: color 200ms, background-color 200ms;
  }

  /* mouse, touch pad */
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: $gray-400;

      .icon-before {
        background-color: $white;
        color: $black;
      }
    }
  }

  &.big {
    font-size: 11px;
    font-weight: bold !important;
    background-color: transparent;
    border: 1px solid white;
    text-transform: none;
    white-space: nowrap;
    border-radius: 30px;
    padding: 14px 20px;
    justify-content: center;

    &:hover {
      color: $black;
      background-color: rgba(255, 255, 255, 1);
    }
  }

  &.btn-outline-white {
    &:hover {
      color: $gray-700;
    }
  }
}
</style>
