<template>
  <transition appear name="slide-down">
    <div class="online-shop-teaser" :class="{ 'back-link': showBackLink }">
      <BaseLink class="shop-link cta subline--regular" :to="href" target="_blank" @click.native="trackClick">
        <div class="content">
          <div class="container-fluid">
            <IconArrowLink class="icon" />
            <template v-if="showBackLink">{{ onlineshopTeaserConfig.backLabel }}</template>
            <template v-else>{{ onlineshopTeaserConfig.forwardLabel }}</template>
          </div>
        </div>
      </BaseLink>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseLink from '@/components/BaseLink'
import IconArrowLink from '@/components/icons/IconArrowLink'

export default {
  name: 'OnlineShopTeaser',
  components: { BaseLink, IconArrowLink },
  data() {
    return {
      showBackLink: false,
      backHref: '',
    }
  },
  created() {
    document.documentElement.style.setProperty('--wmf-content-offset-y', '20px')
    document.documentElement.style.setProperty('--wmf-onlineshop-teaser-height', '30px')

    // get query params that indicate if back or forward link should be shown
    const referrerQueryParam = this.$route.query.referrer ? this.$route.query.referrer : ''
    const utmSource = this.$route.query.utm_source ? this.$route.query.utm_source : ''
    this.backHref = referrerQueryParam.search(/wmf\.com/) >= 0 ? decodeURIComponent(referrerQueryParam) : this.onlineshopTeaserConfig.backHref
    this.showBackLink = utmSource === 'wmfshop' || referrerQueryParam.search(/wmf\.com/) >= 0 || document.referrer.search(/wmf\.com/) >= 0
  },
  computed: {
    ...mapGetters(['onlineshopTeaserConfig']),
    href() {
      return this.showBackLink ? `${this.backHref}` : this.onlineshopTeaserConfig.forwardHref
    },
  },
  methods: {
    trackClick() {
      const dataLayer = window.dataLayer || []
      dataLayer.push({
        event: 'custom_interaction',
        name: 'teaser_home',
        label: '',
      })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.online-shop-teaser {
  //width: 100%;
  position: fixed;
  z-index: $zindex-online-shop-teaser;
  overflow: hidden;

  //@include media-breakpoint-up(md) {
  width: calc(100% - var(--wmf-slide-scroll-bar-width));
  //}

  .content {
    //background: linear-gradient(90deg, rgba(53, 158, 210, 0.8) 0%, rgba(53, 158, 210, 0.8) 10%, #000 100%);
    background: linear-gradient(90deg, #025e8e 0%, #025e8e 10%, #000 100%);
    height: 30px;
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: black;
      height: 100%;
      width: 100%;
      z-index: -1;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(90deg, $blue 0%, $blue 80%, #235f7e 100%);
      //transform: translate(-100%, 0);
      transition: transform 300ms ease-in, opacity 600ms;
    }
  }

  .container-fluid {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
  }

  .cta {
    text-decoration: none;
    transition: color 300ms;
    white-space: nowrap;

    &:hover,
    &:focus {
      color: $white;

      .content:after {
        //transform: translateX(0);
        opacity: 0.8;
      }
    }

    .icon {
      margin-right: 8px;
      font-size: 9px;
      flex: 0 0 auto;
    }
  }

  &.back-link {
    .icon {
      transform: rotate(180deg);
    }
  }
}

.slide-down-enter-active,
.slide-down-leave-active {
  transition: transform 0.5s ease-out 1.2s;
}

.slide-down-enter, .slide-down-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translateY(-100%);
}
</style>
