<template>
  <button append class="btn p-0 text-uppercase font-inter fw-bold d-inline-flex align-items-center letter-spacing-wide">
    <IconArrowDown ref="icon" class="icon" />
    <span class="ms-15px">
      {{ label }}
    </span>
  </button>
</template>

<script>
import IconArrowDown from '@/components/icons/IconArrowDown'
import gsap from 'gsap'

export default {
  components: {
    IconArrowDown,
  },
  data() {
    return {
      teaseTween: undefined,
    }
  },
  props: {
    label: String,
  },
  methods: {
    startTease() {
      const icon = this.$refs.icon.$el
      this.timeline = new gsap.timeline({ repeat: -1 })
      this.timeline.add(
        gsap.to(icon, {
          ease: 'Expo.easeIn',
          y: 10,
          duration: 1,
        })
      )
      this.timeline.add(
        gsap.to(icon, {
          opacity: 0,
          duration: 0.5,
        }),
        '-=0.25'
      )

      this.timeline.add(gsap.set(icon, { y: -5 }))

      this.timeline.add(
        gsap.to(icon, {
          alpha: 1,
          duration: 0.5,
        })
      )
      this.timeline.add(
        gsap.to(icon, {
          ease: 'Expo.easeOut',
          y: 0,
        }),
        '-=0.5'
      )
    },
  },
  destroyed() {
    gsap.killTweensOf(this.$el)

    if (this.timeline) this.timeline.kill()
    this.timeline = undefined
  },
  mounted() {
    gsap
      .to(this.$el, {
        autoAlpha: 1,
        delay: 1,
      })
      .then(this.startTease)
  },
}
</script>

<style scoped lang="scss">
.btn {
  font-weight: 900;
  opacity: 0;
  visibility: hidden;
}

.icon {
  font-size: 0.9em;
}
</style>
