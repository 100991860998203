<template>
  <div class="social-media" v-if="socialMediaLinks.length > 0">
    <div class="social-item" v-for="(item, index) in socialMediaLinks" :key="index">
      <a class="social-link" :href="item.href" target="_blank">
        <Icon :name="item.label" />
      </a>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/Icon'
import { mapGetters } from 'vuex'

export default {
  name: 'SocialMedia',
  components: {
    Icon,
  },
  computed: {
    ...mapGetters(['socialMediaLinks']),
  },
}
</script>

<style scoped lang="scss">
.social-media {
  display: flex;
  margin-top: $spacing-detail-m;
  margin-bottom: $spacing-detail-m;
  justify-content: left;
}

.social-item {
  margin-right: 23px;
}

.social-item:last-child {
  margin-right: 0;
}

@include media-breakpoint-up(lg) {
  .social-media {
    margin-top: $spacing-detail-m;
  }
}

@include media-breakpoint-up(xl) {
  .social-media {
    margin-top: $spacing-detail-m;
  }
}
</style>
