<template>
  <div v-if="isSVG">
    <img class="baseimage svg" draggable="false" :src="`/${$attrs.src}`" :alt="alt" @load="onImgLoad" />
  </div>
  <twic-img v-else-if="config.twicpics && twicpics && !useTwicpicsAPI && $attrs.src" class="baseimage" v-bind="$attrs" :alt="alt" />
  <div
    v-else-if="config.twicpics && twicpics && useTwicpicsAPI"
    class="baseimage"
    :style="{
      'padding-top': paddingTop,
    }"
  >
    <img draggable="false" :src="`${twicpicsDomain}/${$attrs.src}?twic=v1/resize=${imageWidth}`" :alt="alt" @load="onImgLoad" />
  </div>
  <div v-else class="baseimage" :style="{ 'padding-top': paddingTop }">
    <img draggable="false" :src="`/${$attrs.src}`" :alt="alt" @load="onImgLoad" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BaseImage',
  props: {
    alt: String,
    twicpics: {
      type: Boolean,
      default: true,
    },
    useTwicpicsAPI: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters(['config', 'twicpicsDomain']),
    paddingTop() {
      const ratio = this.$attrs.ratio ? this.$attrs.ratio.split('/') : [1, 1]
      return `${100 / (ratio[0] / ratio[1])}%`
    },
    imageWidth() {
      return this.width > 0 ? this.width : screen.width
    },
    isSVG() {
      return this.$attrs.src ? this.$attrs.src.split('.').pop() === 'svg' : false
    },
  },
  methods: {
    onImgLoad(event) {
      this.$emit('load', event)
    },
  },
}
</script>
<style lang="scss" scoped>
.baseimage {
  position: relative;
  width: 100%;
  height: 0;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.svg {
    height: auto;
  }
}
</style>
