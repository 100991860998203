<script>
import Vue from 'vue'
import BaseLink from '@/components/BaseLink'
import { richTextLinks } from '@/helpers/cmsContent'

export default {
  name: 'RichText',
  components: {
    BaseLink,
  },
  props: {
    html: {
      type: String,
      default: '',
    },
    tag: {
      type: String,
      default: 'div',
    },
    css: {
      type: String,
      default: '',
    },
  },
  data() {
    return { templateRender: undefined }
  },
  watch: {
    html() {
      this.updateRender()
    },
  },
  created() {
    this.updateRender()
  },
  methods: {
    updateRender() {
      const compiled = Vue.compile(`<${this.tag}>${richTextLinks(this.html)}</${this.tag}>`)
      this.templateRender = compiled.render
      this.$options.staticRenderFns = []
      for (const staticRenderFunction of compiled.staticRenderFns) {
        this.$options.staticRenderFns.push(staticRenderFunction)
      }
    },
  },
  render() {
    return this.templateRender()
  },
}
</script>
