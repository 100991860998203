import EventBus from './../../../helpers/eventBus.js'
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            currentIndex: 0,
            bufferItems: [],
            pauseBuffering: false,
            complete: false,
            xhr: undefined,
        }
    },
    computed: {
        ...mapGetters(['currentBufferVideo', 'isDesktop']),
    },
    watch: {
        currentBufferVideo() {
            if (this.complete || !this.isDesktop || this.pauseBuffering) return

            if (this.currentBufferVideo) {
                this.pauseBuffer()
            } else {
                this.resumeBuffer()
            }
        },
        pauseBuffering(value) {
            if (value) {
                this.pauseBuffer()
            }
        },
    },
    methods: {
        initBuffer(cmsContent) {
            if (!this.isDesktop) {
                return
            }

            EventBus.$on('video_buffered', this.onVideoBuffered)

            cmsContent.forEach((o) => {
                const main = o.content
                main.forEach((sub) => {
                    if (sub.props) {
                        if (sub.props.animation) {
                            if (sub.props.animation.video) {
                                const vo = {
                                    slug: o.slug,
                                    src: this.getVideoSrc(sub.props.animation.video.src),
                                    buffered: false,
                                }

                                vo.index = this.bufferItems.length
                                this.bufferItems.push(vo)
                            }
                        }
                    }
                })
            })

            this.startBuffer()
        },
        getVideoSrc(src) {
            if (window.innerWidth < 992) {
                return src.replace('assets/videos/', 'assets/videos/small/')
            }
            return src
        },
        onVideoBuffered(file) {
            const buffered = this.bufferItems.find((item) => item.src === file)
            if (buffered) {
                buffered.buffered = true
                this.currentIndex = buffered.index + 1
            }

            this.$store.commit('setCurrentBufferVideo', undefined)
        },
        startBuffer() {
            let current = this.bufferItems[this.currentIndex]

            if (!current) {
                const firstSkipped = this.bufferItems.find((item) => !item.buffered)

                if (firstSkipped) {
                    /*checking for previously skipped videos*/
                    this.currentIndex = firstSkipped.index
                    current = this.bufferItems[this.currentIndex]
                } else {
                    /*all videos buffered*/
                    this.complete = true
                    return
                }
            }

            if (current.buffered) {
                /* skip if video is already buffered */
                this.currentIndex++
                this.startBuffer()
            } else {
                const xhr = new XMLHttpRequest()
                //xhr.responseType = 'arraybuffer'
                xhr.addEventListener('load', this.onXhrLoad)
                xhr.open('get', current.src)
                xhr.send()

                this.xhr = xhr
            }
        },
        onXhrLoad() {
            this.bufferItems[this.currentIndex].buffered = true
            this.currentIndex++

            this.startBuffer()
        },
        pauseBuffer() {
            /* chunk loading? */
            if (this.xhr) {
                this.xhr.abort()
                this.xhr = undefined
            }
        },
        resumeBuffer() {
            this.startBuffer()
        },
        forceBuffer(id) {
            this.pauseBuffer()

            const currentBuffer = this.bufferItems.find((item) => item.src === id)
            if (currentBuffer) this.currentIndex = currentBuffer.index
        },
    },
}
