<template>
  <div class="not-supported" v-if="!isSatisfied">
    <div class="not-supported__container">
      <h2 class="headline headline--regular">
        {{ $t('noSupportHeadline') }}
      </h2>
      <p class="copy" v-html="$t('noSupportCopy')"></p>

      <a :href="$t('noSupportDownloadUrl')" target="_blank">
        {{ $t('noSupportDownloadLabel') }}
      </a>
      <br />
      <a href="#" @click="skip">
        {{ $t('noSupportSkipLabel') }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { disablePageScroll, enablePageScroll } from 'scroll-lock'

export default {
  name: 'NotSupported',
  components: {},
  data() {
    return {
      isSatisfied: undefined,
    }
  },
  computed: {
    ...mapGetters(['browserSupportConfig', 'bowser']),
  },
  watch: {
    isSatisfied() {
      if (!this.isSatisfied) disablePageScroll()
    },
  },
  methods: {
    skip(e) {
      e.preventDefault()
      this.isSatisfied = true
      enablePageScroll()
    },
  },
  mounted() {
    this.isSatisfied = this.browserSupportConfig ? this.bowser.satisfies(this.browserSupportConfig) : true
  },
}
</script>

<style scoped lang="scss">
.not-supported {
  justify-content: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  position: fixed;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;

  background-color: rgba(0, 0, 0, 0.9);

  z-index: $zindex-no-support;

  &__container {
    background-color: $gray-900;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;

    width: calc(100% - 40px);
    max-width: 800px;
    padding: 40px 30px;

    @include media-breakpoint-up(sm) {
      padding: 40px 70px;
    }
  }

  .copy {
    margin-bottom: 20px;
  }

  a {
    font-family: var(--wmf-font-inter);
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 600;
    text-decoration: none;
  }
}
</style>
