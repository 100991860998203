<template>
  <div class="detail-layer white" :class="[size]">
    <div class="header-wrapper container">
      <div class="header" :class="{ small: headerSmall }">
        <button v-if="showBackButton" class="button back-button" @click="$emit('back')">
          <Icon name="ArrowPrev" />
        </button>
        <button class="button close-button" @click="$emit('close')">
          <Icon class="icon" name="Close" />
        </button>
      </div>
    </div>
    <div class="detail-content container" ref="content">
      <component
        :is="module.type"
        v-for="(module, index) in content"
        :key="`module-${module.type}-${index}`"
        v-bind="module.props"
        :size="size"
        @showLightbox="showLightbox"
      />
      <DebugGrid v-if="showDebugGrid" class="container"></DebugGrid>
      <div v-if="outlineText" class="outline">
        <span v-for="(letter, letterIndex) in outlineText" :key="`detail-outline-${letterIndex}`">
          {{ letter }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import NotFound from '@/components/detail/DetailNotFound'
import Icon from '@/components/Icon'
import DebugGrid from '@/components/DebugGrid'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Vue from 'vue'

const detailImports = [
  'DetailDebugLabel',
  'DetailImage',
  'DetailHeadline',
  'DetailCopy',
  'DetailCopyImage',
  'DetailRegistrationForm',
  'DetailTeaser',
  'DebugGrid',
  'DetailCarousel',
  'DetailPush',
  'DetailReceipt',
  'DetailStorefinder',
  'DetailProductOverview',
  'DetailVideo',
  'DetailDownload',
  'DetailAccordion',
  'DetailSpotifyPlayer',
  'DetailFilter',
  'DetailModelComparision',
  'DetailModelOverview',
  'DetailAR',
]

detailImports.forEach((module) => {
  Vue.component(module, () => import('@/components/detail/' + module + '.vue'))
})

export default {
  name: 'Detail',
  components: {
    NotFound,
    Icon,
    DebugGrid,
  },
  props: {
    page: {
      type: [Object],
      default: () => {},
    },
  },
  data() {
    return {
      headerSmall: false,
      showBackButton: false,
      scrollTop: 0,
    }
  },
  activated() {
    this.$nextTick(() => {
      this.$el.scrollTop = this.scrollTop
      this.$nextTick(() => {
        this.scrollTrigger.enable()
      })
    })
  },
  deactivated() {
    this.scrollTop = this.$el.scrollTop
    this.scrollTrigger.disable()
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger)
    this.showBackButton = this.$store.state.showBackButton ? true : false
    this.scrollTrigger = ScrollTrigger.create({
      scroller: this.$el,
      markers: false,
      trigger: this.$refs.content,
      start: 'top top',
      onEnter: () => {
        this.headerSmall = true
      },
      onLeaveBack: () => {
        this.headerSmall = false
      },
    })
  },
  computed: {
    ...mapGetters(['showDebugGrid']),
    content() {
      return this.page.content ? this.page.content : []
    },
    size() {
      return this.page.size ? this.page.size : ''
    },
    outlineText() {
      return this.page.outline ? this.page.outline.split('') : null
    },
  },
  methods: {
    ...mapMutations(['setOpenLightbox']),
    showLightbox(image) {
      const imageModules = this.page.content.filter((module) => module.type === 'DetailImage')
      let lightboxImages = []
      imageModules.forEach((imageModule) => {
        lightboxImages = [...lightboxImages, ...imageModule.props.images.filter((image) => image.fullscreen)]
      })
      const startIndex = lightboxImages.indexOf(image)
      this.setOpenLightbox({
        startIndex: startIndex,
        images: lightboxImages,
      })
    },
  },
}
</script>

<style scoped lang="scss">
.detail-layer {
  position: fixed;
  top: 0;
  right: 0;
  min-height: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: $zindex-detail-layer;
  width: 100%;
  max-width: 655px;
  background-color: $gray-700;

  &.large {
    max-width: 1745px;
  }
}

.detail-content {
  position: relative;
  padding-bottom: $spacing-detail-l;

  .large & {
    padding-bottom: 300px;
  }

  > * {
    margin-top: $spacing-detail-m;

    .large & {
      margin-top: $spacing-detail-xxl;

      &.detail-headline + .detail-image,
      &.detail-headline + .detail-video,
      &.detail-copy + .detail-image,
      &.detail-storefinder,
      &.detail-ar,
      &.detail-copy {
        margin-top: $spacing-detail-m;
      }

      &:first-child {
        &.detail-ar {
          margin-top: 160px;
        }
      }
    }
  }
}

.header-wrapper {
  position: sticky;
  height: 0;
  top: 0;
  left: 0;
  z-index: 10;
}

.header {
  position: relative;
  transition: font-size 300ms;
  font-size: 60px;

  ::v-deep {
    svg {
      width: 1em;
      height: 1em;
    }
  }

  &.small {
    font-size: 20px;

    .button {
      //width: 41px;
      //height: 41px;

      //.icon {
      //  position: absolute;
      //  top: 50%;
      //  left: 50%;
      //  transform: translate(-50%, -50%);
      //}

      &:before {
        transition-delay: 300ms;
        opacity: 1;
      }

      &:hover {
        &:before {
          background-color: $gray-600;
        }
      }
    }

    .back-button {
      font-size: 1em;
      right: 81px;
      top: 40px;
    }

    ::v-deep {
      svg path {
        stroke-width: 4;
      }
    }
  }
}

.button {
  position: absolute;
  top: 40px;
  color: $white;
  background-color: transparent;
  box-shadow: none;
  border: 0;
  margin: 0;
  line-height: 0;
  padding: 10px;
  transition: left 300ms, right 300ms;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 100%;
    z-index: -1;
    left: 50%;
    top: 50%;
    opacity: 0;
    background-color: $black;
    transform: translateX(-50%) translateY(-50%);
    transition: opacity 300ms;
  }

  &:hover {
    color: $gray-400;
  }

  ::v-deep {
    svg path {
      transition: stroke-width 300ms;
      stroke-width: 2;
    }
  }
}

.back-button {
  font-size: 0.6em;
  right: calc(100% + 10px);
  transform: translateX(100%);
  top: 64px;

  ::v-deep {
    svg path {
      stroke-width: 3;
    }
  }
}

.close-button {
  font-size: 1em;
  right: -10px;
}

.outline {
  pointer-events: none;
  font-family: var(--wmf-font-wmfrotis);
  font-weight: $font-weight-bold;
  position: absolute;
  top: 0;
  right: -2.86vw;
  font-size: 15.625vw;
  margin-top: 11.66vw;
  color: transparent;
  text-transform: uppercase;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.6);
  text-stroke: 1px rgba(255, 255, 255, 0.6);
  line-height: 1;
  display: none;
  flex-direction: column;
  align-items: center;

  @include media-breakpoint-up(lg) {
    display: flex;
  }
}
</style>
