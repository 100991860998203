<template>
  <div class="buy-button">
    <div v-if="buyItems.length > 0">
      <BuyButtonRetailerFlyout
        class="retailer-button-flyout"
        :items="buyItems"
        :size="size"
        :flyoutDirectionY="flyoutDirectionY"
        :flyoutDirectionX="flyoutDirectionX"
        :global="global"
      />
      <BuyButtonRetailerOpenLayer class="retailer-button-layer" :items="buyItems" :size="size" />
    </div>
    <BuyButtonSimple v-else :href="buyHref" :size="size" :hideLabel="hideLabel" />
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BuyButtonWrapper',
  components: {
    BuyButtonSimple: () => import('@/components/buttons/buy-button/BuyButtonSimple'),
    BuyButtonRetailerFlyout: () => import('@/components/buttons/buy-button/BuyButtonRetailerFlyout'),
    BuyButtonRetailerOpenLayer: () => import('@/components/buttons/buy-button/BuyButtonRetailerOpenLayer'),
  },
  props: {
    href: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    buy: {
      type: Object,
      default: () => ({}),
    },
    size: {
      type: String,
      default: 'large', // 'small'
    },
    hideLabel: {
      type: Boolean,
      default: true, // 'small'
    },
    flyoutDirectionY: {
      type: String,
      default: 'down', // 'up'
    },
    flyoutDirectionX: {
      type: String,
      default: 'left', // 'right'
    },
    global: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buyItems() {
      return this.buy && this.buy.items ? this.buy.items : this.items
    },
    buyHref() {
      return this.buy && this.buy.href ? this.buy.href : this.href
    },
  },
}
</script>
<style lang="scss" scoped>
.buy-button {
  display: flex;
  align-items: center;
}

.retailer-button-flyout {
  display: none;
}

@include media-breakpoint-up(lg) {
  .retailer-button-flyout {
    display: block;
  }

  .retailer-button-layer {
    display: none;
  }
}
</style>
