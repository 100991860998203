<template>
  <Button
    v-if="locales.length > 0"
    class="button"
    @click.native="$store.commit('setOpenLanguageSelection', true)"
    :aria-label="$t('language_selection')"
    :title="$t('language_selection')"
  >
    <Icon name="Language" />
    <span v-if="showLabel" class="label medium-label">
      {{ $t('language_selection') }}
    </span>
  </Button>
</template>

<script>
import Icon from '@/components/Icon'
import Button from '@/components/buttons/Button'
import { mapGetters } from 'vuex'

export default {
  name: 'LanguageSelectionButton',
  props: {
    showLabel: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Icon,
    Button,
  },
  computed: {
    ...mapGetters(['config', 'locales']),
  },
}
</script>

<style scoped lang="scss">
.button {
  cursor: pointer;
  transition: color 300ms;
  font-size: 17px;
  padding: 10px;

  &:hover {
    color: $gray-400;
  }
}

.label {
  margin-left: 10px;
  text-transform: uppercase;
}
</style>
