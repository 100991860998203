<template>
  <svg :width="widthEm" :height="heightEm" :viewBox="`0 0 ${width} ${height}`" xmlns="http://www.w3.org/2000/svg" aria-labelledby="arrow-next-link">
    <path fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="1.5" d="m7.937 8.37 3.919-3.92L7.937.53" />
    <path fill="none" stroke="currentColor" stroke-width="1.5" d="M12 4.539H0" />
  </svg>
</template>

<script>
import IconBase from '@/components/icons/IconBase'

export default {
  name: 'IconArrowLink',
  extends: IconBase,
  props: {
    width: {
      type: [Number],
      default: 13,
    },
    height: {
      type: [Number],
      default: 9,
    },
  },
}
</script>
