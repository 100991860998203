<template>
  <div class="detail-headline row headroom">
    <div class="col">
      <h2 class="headline">
        {{ $t('notfound') }}
      </h2>
      <div class="button mt-3">
        <Button color="white" outline :tag="'router-link'" v-bind="cta">
          {{ $t('notfound-cta') }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/buttons/Button'

export default {
  name: 'DetailNotFound',
  components: {
    Button,
  },
  computed: {
    cta() {
      return {
        href: '/',
      }
    },
  },
}
</script>

<style scoped lang="scss">
.headline {
  padding: 0;
  margin: 0;
}

.subline {
  padding: 4px 0;
  margin: 0;
}

.headroom {
  padding-top: 205px;
}
</style>
