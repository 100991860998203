<template>
  <header class="header" :class="{ 'show-main-navigation': showMainNavigation }" @keyup.esc="closeNavigation" tabindex="0">
    <div class="darker" @click="closeNavigation"></div>
    <div class="wrapper">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="content">
              <div class="left">
                <Button
                  class="toggle-menu-button"
                  @click.native="showMainNavigation ? closeNavigation() : openNavigation()"
                  :aria-label="$t('aria.toggleMenu')"
                >
                  <Icon class="icon" name="Burger" :class="{ close: showMainNavigation }" />
                </Button>
                <SubNavigation v-if="showChapterNavigation" :class="{ hidden: showMainNavigation }"></SubNavigation>
              </div>
              <div class="center">
                <router-link class="logo" :to="`/${$route.params.lang}`" role="button" :aria-label="$t('aria.home')">
                  <Icon name="Logo" />
                </router-link>
              </div>
              <div class="right">
                <div class="right-content-wrapper">
                  <div class="lanuage-selection-button">
                    <LanguageSelectionButton class="tiny-label" :aria-label="$t('language_selection')" />
                  </div>
                  <a v-if="config.cta" :href="config.cta.href" target="_blank" class="cta tiny-label">
                    {{ config.cta.label }}
                  </a>
                  <BuyButton v-if="showBuyButton" :global="true" :items="config.buy.items" :href="config.buy.href" :hideLabel="false" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <transition
        @before-enter="beforeEnterNavigation"
        @enter="enterNavigation"
        @before-leave="beforeLeaveNavigation"
        @leave="leaveNavigation"
        v-bind:css="false"
        mode="out-in"
      >
        <MainNavigation v-if="showMainNavigation"></MainNavigation>
      </transition>
    </div>
  </header>
</template>

<script>
import Icon from '@/components/Icon'
import Button from '@/components/buttons/Button'
import LanguageSelectionButton from '@/components/buttons/LanguageSelectionButton'
import BuyButton from '@/components/buttons/BuyButton'
import { addScrollableSelector, disablePageScroll, enablePageScroll } from 'scroll-lock'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { mediaBreakpointUp } from '@/helpers/mediaQueries'
import EventBus from '@/helpers/eventBus'
import { gsap } from 'gsap'
import { mapGetters } from 'vuex'

export default {
  name: 'LargeHeader',
  components: {
    Icon,
    LanguageSelectionButton,
    MainNavigation: () => import('@/components/navigation/MainNavigation'),
    SubNavigation: () => import('@/components/navigation/SubNavigation'),
    Button,
    BuyButton,
  },
  data() {
    return {
      showMainNavigation: false,
      breakPointUpLarge: false,
    }
  },
  mounted() {
    EventBus.$on('resize-end', this.onResize)
    this.breakPointUpLarge = mediaBreakpointUp('lg')
  },
  destroyed() {
    EventBus.$off('resize-end', this.onResize)
  },
  computed: {
    ...mapGetters(['config', 'currentChapter', 'chapters']),
    showBuyButton() {
      return this.config.buy.show
    },
    showChapterNavigation() {
      return (
        this.currentChapter !== '' &&
        this.breakPointUpLarge &&
        this.chapters.find((chapter) => {
          if (chapter.id === this.currentChapter) {
            return true
          } else if (chapter.chapters && chapter.chapters.length > 0) {
            return chapter.chapters.find((subchapter) => subchapter.id === this.currentChapter)
          }
        })
      )
    },
  },
  methods: {
    openNavigation() {
      EventBus.$emit('open-main-navigation')
      this.showMainNavigation = true
    },
    closeNavigation() {
      EventBus.$emit('close-main-navigation')
      this.showMainNavigation = false
    },
    beforeEnterNavigation() {
      addScrollableSelector('.header')
      disablePageScroll()
      this.updateScrollbarWitdh()
    },
    enterNavigation(el, done) {
      gsap.set(el, { opacity: 1 })
      gsap.from(el, {
        opacity: 0,
        duration: 0.4,
        onComplete: () => {
          done()
        },
      })
    },
    beforeLeaveNavigation() {
      gsap.registerPlugin(ScrollToPlugin)
      enablePageScroll()
      this.updateScrollbarWitdh()
    },
    leaveNavigation(el, done) {
      const tl = gsap.timeline({
        onComplete: () => {
          done()
        },
      })
      tl.set(el, { opacity: 1 })
      tl.to(el, {
        opacity: 0,
        duration: 0.4,
      })
      if (this.$el.scrollTop > 0) {
        tl.set(this.$el, {
          scrollTo: {
            y: 0,
          },
        })
        tl.from(this.$el, {
          opacity: 0,
          duration: 0.4,
        })
      }
    },
    updateScrollbarWitdh() {
      this.$nextTick(() => {
        const scrollBarWidth = Math.abs(this.$el.clientWidth - document.documentElement.clientWidth)
        document.documentElement.style.setProperty('--wmf-header-scroll-bar-width', `${scrollBarWidth}px`)
      })
    },
    onResize() {
      this.breakPointUpLarge = mediaBreakpointUp('lg')
      this.updateScrollbarWitdh()
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        gsap.delayedCall(0.2, () => {
          this.closeNavigation()
        })
      },
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header {
  color: white;
  margin-top: var(--wmf-onlineshop-teaser-height);
  position: fixed;
  //width: 100%;
  width: calc(100% - var(--wmf-slide-scroll-bar-width));
  top: 0;
  z-index: $zindex-header;
  max-height: 100%;
  //overflow-y: hidden;
  //overflow-x: hidden;

  .darker {
    content: '';
    position: fixed;
    width: calc(100% - var(--wmf-slide-scroll-bar-width));
    height: 0;
    margin-top: var(--wmf-onlineshop-teaser-height);
    top: 0;
    left: 0;
    background-color: $black;
    opacity: 0;
    transition: opacity 600ms cubic-bezier(0.39, 0.575, 0.565, 1);
    z-index: -1;
  }

  &.show-main-navigation {
    width: 100%;
    overflow: auto;

    .darker {
      height: 100vh;
      opacity: 0.8;
    }
  }
}

.wrapper {
  padding: 30px 0 0;
  width: calc(100% + var(--wmf-slide-scroll-bar-width));
  position: relative;

  &:before,
  &:after {
    content: '';
    transition: opacity;
    opacity: 0;
    background: black;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    transition: opacity 400ms;
    z-index: -1;
  }

  &:after {
    background: linear-gradient(45deg, #2d3340ff -40%, #2d334000 90%);
    width: 50%;
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  .show-main-navigation & {
    &:before,
    &:after {
      opacity: 1;
    }

    @include media-breakpoint-up(lg) {
      width: calc(100% + var(--wmf-header-scroll-bar-width));
    }
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 64px;
}

.left {
  display: flex;
  flex: 0 1 auto;
  width: calc(50% - 100px);
}

.right {
  flex: 0 1 auto;
  width: calc(50% - 100px);
}

.toggle-menu-button {
  font-size: 28px;
  padding: 10px 0;
}

.subnavigation {
  margin-left: 40px;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
}

.logo {
  font-size: 47px;
  line-height: 0;
  color: #fff;

  @include media-breakpoint-up(lg) {
    font-size: 64px;
  }

  a {
    &:hover,
    &:focus {
      color: #fff;
    }
  }
}

.right-content-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .lanuage-selection-button {
    display: none;
    margin-right: 15px;

    @include media-breakpoint-up(lg) {
      display: flex;
    }
  }

  .cta {
    transition: color 300ms;
    display: none;
    white-space: nowrap;
    text-transform: uppercase;
    line-height: 1;

    text-decoration: none;

    &:hover,
    &:focus {
      color: $gray-400;
    }

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }
}

.buy-button {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}
</style>
