<template>
  <div class="container">
    <div class="text-uppercase text-white-15 font-inter">Colors</div>
    <div class="row mt-2">
      <div class="col-12 col-md-7">
        <div class="d-flex flex-row justify-content-between">
          <div class="h-60px w-60px bg-blue"></div>
          <div class="h-60px w-60px bg-red-900"></div>
          <div class="h-60px w-60px bg-brown"></div>
          <div class="h-60px w-60px bg-brown-100"></div>
          <div class="h-60px w-60px bg-gray-100"></div>
          <div class="h-60px w-60px bg-gray-500"></div>
          <div class="h-60px w-60px bg-gray-600"></div>
          <div class="h-60px w-60px bg-gray-700"></div>
          <div class="h-60px w-60px bg-gray-800"></div>
          <div class="h-60px w-60px bg-gray-900"></div>
        </div>
      </div>
    </div>
    <div class="mt-5 text-uppercase text-white-15 font-inter">Typography</div>
    <div class="row mt-2">
      <div class="col">
        <h1 class="text-uppercase">Headline Gross</h1>
        <OutlineText>
          Kaffee
          <br />
          genuss
        </OutlineText>
        <div class="row">
          <div class="col">
            <h2 class="text-uppercase">Headline</h2>
          </div>
          <div class="col">
            <h2 class="text-uppercase">
              Headline
              <br />
              zweizeilig
            </h2>
          </div>
        </div>
        <h3>Subline</h3>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="text-uppercase text-white-15 font-inter">Paragraph (WMFRotis)</div>
        <p class="font-wmfrotis mt-2">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed
          diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata
        </p>
      </div>
      <div class="col">
        <div class="text-uppercase text-white-15 font-inter">Paragraph (Inter)</div>
        <p class="mt-2">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed
          diam voluptua.
          <strong>At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata.</strong>
          <i>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
            sed diam voluptua.
          </i>
          <a href="/">Lorem ipsum dolor sit amet</a>
        </p>
      </div>
    </div>
    <div class="mt-5 text-uppercase text-white-15 font-inter">Card</div>
    <div class="row mt-2">
      <div class="col-12 col-md-5">
        <Card>
          <template v-slot:card-header>Lorem ipsum</template>
          <template v-slot:card-body>
            Lorem ipsum dolor sit amet incididunt molestie lectus aliquet tortor nisl ut tempor. Mauris adipiscing porta est vulputate tincidunt cras
            congue id cursus.
          </template>
          <template v-slot:card-footer>
            <Button icon-before="Plus">More</Button>
          </template>
        </Card>
      </div>
    </div>
    <div class="mt-5 text-uppercase text-white-15 font-inter">Icons</div>
    <div class="row mt-2">
      <div class="col">
        <Icon name="ArrowUp" />
        <pre>ArrowUp</pre>
      </div>
      <div class="col">
        <Icon name="ArrowPrev" />
        <pre>ArrowPrev</pre>
      </div>
      <div class="col">
        <Icon name="ArrowNext" />
        <pre>ArrowNext</pre>
      </div>
      <div class="col">
        <Icon name="Logo" />
        <pre>Logo</pre>
      </div>
      <div class="col">
        <Icon name="Play" />
        <pre>Play</pre>
      </div>
      <div class="col">
        <Icon name="Plus" />
        <pre>Plus</pre>
      </div>
      <div class="col">
        <Icon name="Close" />
        <pre>Close</pre>
      </div>
      <div class="col">
        <Icon name="Download" />
        <pre>Download</pre>
      </div>
      <div class="col">
        <Icon name="ArrowRightLarge" />
        <pre>ArrowRightLarge</pre>
      </div>
      <div class="col">
        <Icon name="ArrowDown" />
        <pre>ArrowDown</pre>
      </div>
    </div>
    <div class="mt-5 text-uppercase text-white-15 font-inter">Button</div>
    <div class="row mt-2">
      <div class="col-12 col-md-2">
        <Button icon-before="Play">Play</Button>
      </div>
      <div class="col-12 col-md-2">
        <Button icon-before="Plus">More</Button>
      </div>
      <div class="col-12 col-md-3">
        <Button color="white" outline>Jetzt anmelden</Button>
      </div>
      <div class="col-12 col-md-2">
        <Button color="white" outline icon-after="ArrowUp">Mehr</Button>
      </div>
      <div class="col-12 col-md-2">
        <Button color="gray-600" icon-after="ArrowUp">Mehr</Button>
      </div>
      <div class="col-12 col-md-2">
        <Button icon-before="Download">Download</Button>
      </div>
      <div class="col-12 col-md-3">
        <MoreButton>Mehr erfahren</MoreButton>
      </div>
      <div class="col-12 col-md-3">
        <MoreButton tag="a" href="#">Mehr erfahren</MoreButton>
      </div>
      <div class="col-12 col-md-3">
        <ScrollButton to="/" label="Scroll"></ScrollButton>
      </div>
      <div class="col-12 col-md-3 d-flex justify-content-between">
        <ArrowButton class="d-inline-block" to="/" :prev="true"></ArrowButton>
        <ArrowButton class="d-inline-block" to="/"></ArrowButton>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/buttons/Button'
import ArrowButton from '@/components/buttons/ArrowButton'
import Card from '@/components/Card'
import Icon from '@/components/Icon'
import MoreButton from '@/components/buttons/MoreButton'
import ScrollButton from '@/components/buttons/ScrollButton'
import OutlineText from '@/components/OutlineText'

export default {
  name: 'Styleguide',
  components: {
    Button,
    Card,
    Icon,
    MoreButton,
    ArrowButton,
    ScrollButton,
    OutlineText,
  },
}
</script>
