<template>
  <svg :width="widthEm" :height="heightEm" :viewBox="`0 0 ${width} ${height}`" xmlns="http://www.w3.org/2000/svg" aria-labelledby="arrow-down">
    <path
      d="M2872.086,3533.276l9.83,9.83-9.83,9.831"
      transform="translate(3553.467 -2871.556) rotate(90)"
      fill="none"
      stroke="currentColor"
      stroke-miterlimit="10"
      stroke-width="1.5"
    />
  </svg>
</template>

<script>
import IconBase from '@/components/icons/IconBase'

export default {
  name: 'IconArrowDown',
  extends: IconBase,
  props: {
    width: {
      type: [Number],
      default: 20.721,
    },
    height: {
      type: [Number],
      default: 11.421,
    },
  },
}
</script>
