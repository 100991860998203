import './assets/styles/main.scss'
import '@/assets/styles/klaro.scss'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { initKlaro } from './helpers/klaro'
import VueTwicpics from '@twicpics/vue'
import '@twicpics/vue/dist/vuetwicpics.css'

import VueI18n from 'vue-i18n'
import Bowser from 'bowser'

Vue.config.productionTip = false

const init = () => {
    initBowser()
    initKlaro()

    Vue.use(VueTwicpics, {
        domain: store.getters.twicpicsDomain,
        defaults: {
            focus: 'auto',
            placeholder: 'preview',
            steps: 10,
        },
    })

    Vue.use(VueI18n)

    const messages = store.getters.translations
    const i18n = new VueI18n({
        locale: 'default', // set locale
        messages, // set locale messages
    })

    if (history.scrollRestoration) {
        history.scrollRestoration = 'manual'
    }

    new Vue({
        router,
        store,
        i18n,
        render: (h) => h(App),
    }).$mount('#app')
}

const initBowser = () => {
    store.commit('setBowser', Bowser.getParser(window.navigator.userAgent))
}

const addStaticContent = (cmsContent) => {
    if (cmsContent.config.ar && cmsContent.config.ar.detailPage) {
        cmsContent.pages.push(cmsContent.config.ar.detailPage)
    }
    return cmsContent
}

let jsonData = {}
const metaBaseElement = document.querySelector('meta[property="itm-sv:perfectionbase"]')
const basePath = metaBaseElement ? metaBaseElement.getAttribute('content') : `/${document.documentElement.lang}/`
// fetch('http://localhost:8010/proxy/de-v2/jsonloader-filtered.json')
fetch(`${basePath}jsonloader-filtered.json`)
    .then(async (response) => {
        jsonData = await response.json()
        addStaticContent(jsonData)
        store.commit('setCmsContent', jsonData)
        init()
    })
    .catch(() => {
        // load fallback in local and internal staging environment
        if (window.location.href.match(/perfection.wmf.com/) === null) {
            fetch('/assets/data/data.json').then(async (response) => {
                jsonData = await response.json()
                addStaticContent(jsonData)
                store.commit('setCmsContent', jsonData)
                init()
            })
        }
    })
// log the current version from package.json
if (process.env.PACKAGE_VERSION) {
    console.log(
        '%cversion:%c%s',
        'color:#FFFFFF;background-color:#4A4A4A;border-radius:2px 0 0 2px;padding:1px 8px',
        'color:#262626;background-color:#FE6500;border-radius:0 2px 2px 0;padding:1px 8px',
        process.env.PACKAGE_VERSION
    )
}
