<template>
  <div
    class="slide"
    :class="{
      'slide--scrollable': contentVisible,
    }"
  >
    <Animation
      v-if="animation"
      v-bind="animation"
      :subpages="subpages"
      :navigation="navigation"
      :fullVideo="fullVideo"
      :next="next"
      v-on:content-visible="contentVisible = true"
    ></Animation>
    <SubpageNavigation v-if="subpages && contentVisible" v-bind="subpages" :showOnMobile="navigation === 'inline'"></SubpageNavigation>
    <slot></slot>
  </div>
</template>

<script>
import Animation from '@/components/main/Animation'
import SubpageNavigation from '@/components/main/SubpageNavigation'
import scrollThresholdMixin from '@/components/mixins/scroll/scrollThresholdMixin.js'
import gsap from 'gsap'
import { mapGetters } from 'vuex'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import EventBus from '@/helpers/eventBus'

export default {
  name: 'Slide',
  components: {
    Animation,
    SubpageNavigation,
  },
  props: {
    animation: Object,
    scrollCta: Object,
    subpages: Object,
    next: String,
    prev: String,
    navigation: String,
    fullVideo: Boolean,
  },
  mixins: [scrollThresholdMixin],
  data() {
    return {
      contentVisible: false,
    }
  },
  mounted() {
    this.$el.scrollTop = 0
    gsap.registerPlugin(ScrollToPlugin)
    EventBus.$on('resize-end', this.onResize)
    this.$el.addEventListener('scroll', this.onSlideScroll, { passive: true })
    gsap.delayedCall(1, this.onSlideScroll)
  },
  destroyed() {
    gsap.delayedCall(0.2, () => {
      // reset scrolling on iOS
      document.documentElement.scrollTo(0, 0)
      window.scrollTo(0, 0)
    })
    EventBus.$off('resize-end', this.onResize)
    this.$el.removeEventListener('scroll', this.onSlideScroll)
    document.documentElement.style.setProperty('--wmf-slide-scroll-bar-width', `${0}px`)
  },
  computed: {
    ...mapGetters(['bowser']),
  },
  watch: {
    contentVisible() {
      setTimeout(() => {
        this.updateScrollbarWitdh()
      }, 50)
    },
  },
  methods: {
    changePage(next, skipVideo) {
      this.$store.commit('incPageScrollCounter', next)
      this.$store.commit('skipVideo', skipVideo)
      this.$router.push({
        path: `/${this.$route.params.lang}/${next}/`,
        query: this.$route.query,
      })
    },
    updateScrollbarWitdh() {
      this.$nextTick(() => {
        const scrollBarWidth = Math.abs(this.$el.clientWidth - document.documentElement.clientWidth)
        document.documentElement.style.setProperty('--wmf-slide-scroll-bar-width', `${scrollBarWidth}px`)
      })
    },
    onResize() {
      this.updateScrollbarWitdh()
    },
    onSlideScroll() {
      EventBus.$emit('scroll-position', this.$el.scrollTop)
    },
  },
}
</script>

<style scoped lang="scss">
.slide {
  position: relative;
  width: 100%;
  //overscroll-behavior-y: none;

  //@include media-breakpoint-up(lg) {
  /*removes double scrollbars*/
  height: 100%;
  max-height: 100vh;

  overflow-y: hidden;
  overflow-x: hidden;

  &--scrollable {
    overflow-y: auto;
  }

  //}
}
</style>
