import store from '../store'

export const initKlaro = () => {
    Object.defineProperties(window, {
        _spotifyConsent: {
            value: false,
            writable: true,
        },
        spotifyConsent: {
            get: function () {
                return this._spotifyConsent
            },
            set: function (val) {
                this._spotifyConsent = val
                store.commit('setSpotifyConsent', this._spotifyConsent)
            },
        },
    })
}
