import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '@/views/Main'
import Detail from '@/views/Detail'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/cookie-settings',
        name: 'klaro-settings',
        beforeEnter() {
            if (window.klaro) {
                window.klaro.show()
            }
        },
    },
    {
        path: '/',
        redirect: () => {
            return `/${getConfigLang()}/${getDefaultMainSlug()}/`
        },
    },
    {
        path: '/:lang/',
        redirect: () => {
            return `/:lang/${getDefaultMainSlug()}/`
        },
    },
    {
        path: '/:lang/:main/',
        name: 'Main',
        component: Main,
        components: {
            default: Main,
            detail: Detail,
        },
        props: {
            default: (route) => {
                const page = getPageBySlug(route.params.main)

                store.commit('setCurrentChapter', page.chapter ? page.chapter : '')
                return { page: page }
            },
            detail: (route) => {
                const page = getPageBySlug(route.params.main)
                return {
                    page: page,
                }
            },
        },
    },
    {
        path: '/:lang/:main/:detail',
        name: 'Detail',
        component: Detail,
        components: {
            default: Main,
            detail: Detail,
        },
        meta: {
            showDetail: true,
        },
        props: {
            default: (route) => {
                return {
                    page: getPageBySlug(route.params.main),
                }
            },
            detail: (route) => {
                const page = getPageBySlug(route.params.detail)
                if (!route.params.detail) {
                    router.replace({
                        path: `/${route.params.lang}/${getDefaultMainSlug()}/${route.params.main}/`,
                    })
                } else if (page.view === 'Main') {
                    router.replace({
                        path: `/${route.params.lang}/${route.params.detail}/`,
                    })
                }
                return {
                    page: getPageBySlug(route.params.detail),
                }
            },
        },
    },
    {
        path: '/:lang/:main/:detail/:nextdetail',
        name: 'NextDetail',
        redirect: () => {
            return `/:lang/:main/:nextdetail/`
        },
    },
]

const getConfigLang = () => {
    const language = store.state.cmsContent.config.language
    return language ? language : 'en'
}

const getPageBySlug = (slug) => {
    const page = store.state.cmsContent.pages.find((page) => page.slug === slug)
    return page ? page : getNotFoundPage()
}

const getNotFoundPage = () => {
    return {
        buy_button: true,
        footer: true,
        content: [
            {
                type: 'NotFound',
            },
        ],
    }
}

const getDefaultMainSlug = () => {
    const page = store.state.cmsContent.pages.find((page) => page.view === 'Main')
    return page && page.slug ? page.slug : ''
}

const hasQueryParams = (route) => {
    return !!Object.keys(route.query).length
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

router.getDefaultMainSlug = getDefaultMainSlug
router.getPageBySlug = getPageBySlug

let utmQueryParams = null

router.beforeEach((to, from, next) => {
    // store initial utm params and assign them to route changes
    if (utmQueryParams === null && to.query.utm_source) {
        utmQueryParams = to.query
    }
    if (!hasQueryParams(to) && utmQueryParams !== null) {
        next({
            path: to.path,
            query: utmQueryParams,
        })
    } else {
        next()
    }
})

router.afterEach(() => {
    store.commit('showBackButton', false)
})

export default router
