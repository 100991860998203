<template>
  <div class="debug-grid">
    <div class="row">
      <div class="col-1">
        <div class="content"></div>
      </div>
      <div class="col-1">
        <div class="content"></div>
      </div>
      <div class="col-1">
        <div class="content"></div>
      </div>
      <div class="col-1">
        <div class="content"></div>
      </div>
      <div class="col-1">
        <div class="content"></div>
      </div>
      <div class="col-1">
        <div class="content"></div>
      </div>
      <div class="col-1">
        <div class="content"></div>
      </div>
      <div class="col-1">
        <div class="content"></div>
      </div>
      <div class="col-1">
        <div class="content"></div>
      </div>
      <div class="col-1">
        <div class="content"></div>
      </div>
      <div class="col-1">
        <div class="content"></div>
      </div>
      <div class="col-1">
        <div class="content"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DebugGrid',
}
</script>

<style scoped lang="scss">
.debug-grid {
  pointer-events: none;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1000;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.row {
  height: 100%;
}

.content {
  background-color: aquamarine;
  opacity: 0.1;
  height: 100%;
}
</style>
