<template>
  <div class="flex-grow-1 overflow-hidden" :class="page.footer ? 'has-footer' : ''" v-if="page && page.view === 'Main'">
    <component
      v-for="(module, index) in content"
      :key="`module-${module.type}-${index}`"
      :is="module.type"
      v-bind="module.props"
      :navigation="page.navigation"
      :buyButton="page.buy_button"
      :fullVideo="page.full_video"
    >
      <Footer v-if="page.footer"></Footer>
    </component>
    <div v-if="showDebugGrid" class="main-debug-grid">
      <DebugGrid class="container-fluid"></DebugGrid>
    </div>
  </div>
</template>

<script>
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import { mapGetters } from 'vuex'
import Slide from '@/components/main/Slide'
import NotFound from '@/components/main/NotFound'
import Styleguide from '@/components/Styleguide'
import Footer from '@/components/Footer'
import DebugGrid from '@/components/DebugGrid'

export default {
  name: 'Main',
  components: {
    Styleguide,
    Slide,
    NotFound,
    Footer,
    DebugGrid,
  },
  props: {
    page: {
      type: [Object],
      default: () => {},
    },
  },
  computed: {
    ...mapGetters(['showDebugGrid']),
    content() {
      return this.page && this.page.content ? this.page.content : []
    },
  },
  mounted() {
    if (this.page && (this.page.buy_button === false || this.page.buy_button === undefined)) {
      this.$store.commit('showBuyButton', false)
    } else {
      this.$store.commit('showBuyButton', true)
    }
  },
}
</script>

<style lang="scss" scoped>
.main-debug-grid {
  width: calc(100% - var(--wmf-slide-scroll-bar-width));
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;

  .container-fluid {
    max-width: 1440px;
  }
}
</style>
