<template>
  <div class="not-found container">
    <div class="row">
      <div class="col-12">
        <h2 class="headline headline--regular">
          {{ $t('notfound') }}
        </h2>
        <MoreButton class="cta mt-3" v-bind="cta">
          {{ $t('notfound-cta') }}
        </MoreButton>
      </div>
    </div>
  </div>
</template>

<script>
import MoreButton from '@/components/buttons/MoreButton'

export default {
  name: 'NotFound',
  components: {
    MoreButton,
  },
  computed: {
    cta() {
      return {
        href: '/',
      }
    },
  },
}
</script>

<style scoped lang="scss">
.not-found {
  justify-content: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @include media-breakpoint-up(lg) {
    margin-left: calc(100% / (12 / 2) + 20px);
  }
}
</style>
