<template>
  <div class="outline-text">
    <div class="d-none" ref="slot">
      <slot></slot>
    </div>
    <svg :width="width" :height="height" :viewBox="`0 0 ${width} ${height}`" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient :id="`gradient_${_uid}`" x1="1" x2="0" y1="0" y2="1">
          <stop stop-color="#4B4949" offset="0%" />
          <stop stop-color="#F5F7F8" offset="22%" />
          <stop stop-color="#DBE7F2" offset="41%" />
          <stop stop-color="#0E6EC1" offset="53%" />
          <stop stop-color="#BFCEDB" offset="69%" />
          <stop stop-color="#5C5959" offset="100%" />
        </linearGradient>
      </defs>
      <g>
        <text
          x="0"
          y="0"
          class="h1 font-wmfrotis text-uppercase"
          :class="size === 'small' ? 'display-140' : 'display-167'"
          stroke-width="1.5"
          :stroke="`url(#gradient_${_uid})`"
          fill="none"
          ref="text"
        ></text>
      </g>
    </svg>
  </div>
</template>

<script>
const LINE_HEIGHT_FACTOR = 0.8

export default {
  props: {
    size: {
      type: String,
      default: 'large',
    },
  },
  data() {
    return {
      height: 1,
      width: window.innerWidth,
      lineHeight: null,
      y_offset: null,
      lines: [],
      debounceTimer: undefined,
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.setLines()
      this.generateText()

      this.onResize()
    })
  },
  created() {
    window.addEventListener('resize', this.onResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize)

    if (this.debounceTimer) clearTimeout(this.debounceTimer)
    this.debounceTimer = undefined
  },
  methods: {
    onResize() {
      if (this.debounceTimer) clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(this.generateText, 100)
    },

    setLines() {
      this.lines = this.$refs.slot.innerHTML.split(/<br[\sa-z0-9-A-Z="]+><\/br>|<br[\sa-z0-9-A-Z="]+>|###/).map((line) => line.trim())
    },
    generateText() {
      const style = window.getComputedStyle(this.$refs.text, null)
      this.lineHeight = parseFloat(style.getPropertyValue('line-height'))
      this.y_offset = parseInt(this.lineHeight * LINE_HEIGHT_FACTOR)
      this.$refs.text.innerHTML = ''
      this.lines.forEach((line, i) => {
        const tspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan')
        const text = document.createTextNode(line)
        tspan.setAttribute('x', 0)
        tspan.setAttribute('dy', this.lines.length > 1 ? i * this.y_offset : this.y_offset)
        tspan.appendChild(text)
        this.$refs.text.appendChild(tspan)
        this.$refs.text.setAttribute('y', i * this.y_offset)
      })

      this.calculateBounds()
      this.$nextTick(this.calculateBounds)
    },
    calculateBounds() {
      const { width, height } = this.$refs.text.getBBox()

      this.height = height //this.lineHeight + (this.lines.length - 1) * this.y_offset
      this.width = width
    },
  },
}
</script>

<style scoped lang="scss">
.outline-text {
  padding: 0;
}

svg text {
  opacity: 0.35;
  letter-spacing: 3px;
}

svg {
  max-width: 100%;

  @include media-breakpoint-up(md) {
    max-width: calc(100% - 60px);
  }
}
</style>
