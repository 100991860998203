const regexAnchor = /<a[^>]*>.*?<\/a>/gim

export const richTextLinks = (cmsContent) => {
    return cmsContent.replace(regexAnchor, (anchor) => {
        let anchorHtmlElement = htmlToElement(anchor) // new DOMParser().parseFromString(anchor, 'text/html')
        let label = anchorHtmlElement.children.length > 0 ? anchorHtmlElement.lastChild.innerHTML : anchorHtmlElement.innerHTML
        let attributes = []
        if (anchorHtmlElement.hasAttribute('href')) {
            attributes.push('to="' + anchorHtmlElement.getAttribute('href') + '"')
        }
        if (anchorHtmlElement.hasAttribute('href') && anchorHtmlElement.getAttribute('href').indexOf('mailto') >= 0) {
            attributes.push('target="_self"')
        } else if (anchorHtmlElement.hasAttribute('target')) {
            attributes.push('target="' + anchorHtmlElement.getAttribute('target') + '"')
        }
        if (anchorHtmlElement.hasAttribute('title')) {
            attributes.push('title="' + anchorHtmlElement.getAttribute('title') + '"')
        }
        return '<base-link ' + attributes.join(' ') + '>' + label + '</base-link>'
    })
}

export const htmlToElement = (html) => {
    var div = document.createElement('div')
    div.innerHTML = html.trim()
    return div.children[0]
}
