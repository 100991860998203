<template>
  <a v-if="isExternalLink" :href="$attrs.to" :target="$attrs.target" :title="$attrs.title">
    <slot></slot>
  </a>
  <router-link v-else @click.native="onClick" :to="internalLink">
    <slot></slot>
  </router-link>
</template>

<script>
import linkMixin from '@/components/mixins/linkMixin'

export default {
  name: 'BaseLink',
  mixins: [linkMixin],
  props: {
    enableBackButton: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onClick(event) {
      if (!this.enableBackButton) {
        return true
      }
      // show back button after a click on rich text link if target is on detail level
      if (event.currentTarget.getAttribute('href').length > 2) {
        this.$store.commit('showBackButton', true)
      }
    },
  },
  computed: {
    href() {
      return this.$attrs.to
    },
  },
}
</script>
