<template>
  <div
    id="app"
    :class="{
      ['show-detail']: showDetail,
      [`${headerType.toLowerCase()}-header`]: true,
    }"
  >
    <transition name="orientation">
      <div v-if="isTablet && displayNotice" class="orientation-notice">
        <div class="notice">
          <IconOrientationNotice />
        </div>
      </div>
    </transition>
    <OnlineShopTeaser v-if="onlineshopTeaserConfig.show"></OnlineShopTeaser>
    <Component :is="`${headerType}Header`"></Component>
    <div class="header-background"></div>
    <div class="main" ref="main">
      <transition name="main-fade" v-bind:css="true">
        <router-view :key="$route.params.main"></router-view>
      </transition>
    </div>
    <SidebarNavigation v-if="headerType !== 'Large'" />
    <transition
      name="detail-slide"
      appear
      @after-appear="appearDetail"
      @before-enter="beforeEnterDetail"
      @enter="enterDetail"
      @after-enter="afterEnterDetail"
      @leave="leaveDetail"
      @after-leave="afterLeaveDetail"
      v-bind:css="false"
      mode="out-in"
    >
      <keep-alive max="4">
        <router-view
          v-if="showDetail"
          name="detail"
          @close="closeDetail"
          @back="backDetail"
          :key="`${$route.params.detail}-${$store.state.showBackButton}`"
        ></router-view>
      </keep-alive>
    </transition>

    <transition name="fade-buy">
      <BuyButton v-if="showBuyButton" :global="true" :items="config.buy.items" :href="config.buy.href" :hideLabel="false" />
    </transition>

    <transition name="fade">
      <BuyButtonRetailerLayer v-if="openRetailerLayer" />
    </transition>

    <transition name="fade">
      <LanguageSelection v-if="this.$store.getters.openLanguageSelection" />
    </transition>

    <transition name="fadeLightbox">
      <Lightbox v-if="this.$store.getters.openLightbox" />
    </transition>

    <NoBrowserSupport />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { gsap } from 'gsap'
import { disablePageScroll, enablePageScroll, addLockableSelector, addScrollableSelector } from 'scroll-lock'
import BuyButton from '@/components/buttons/BuyButton'
import LargeHeader from '@/components/header/LargeHeader'
import OnlineShopTeaser from '@/components/header/OnlineShopTeaser'
import videoBufferMixin from '@/components/mixins/buffer/videoBufferMixin.js'
import resizeMixin from '@/components/mixins/resizeMixin.js'
import NoBrowserSupport from './components/main/NoBrowserSupport.vue'
import EventBus from '@/helpers/eventBus.js'
import { initTrackingService } from '@/helpers/trackingService.js'

export default {
  components: {
    LargeHeader,
    SimpleHeader: () => import('@/components/header/SimpleHeader'),
    SidebarNavigation: () => import('@/components/navigation/SidebarNavigation'),
    BuyButton,
    BuyButtonRetailerLayer: () => import('@/components/buttons/buy-button/BuyButtonRetailerLayer'),
    LanguageSelection: () => import('@/components/language/LanguageSelection'),
    NoBrowserSupport,
    IconOrientationNotice: () => import('@/components/icons/IconOrientationNotice'),
    OnlineShopTeaser,
    Lightbox: () => import('@/components/Lightbox'),
  },
  mixins: [videoBufferMixin, resizeMixin],
  data() {
    return {
      showDetail: false,
      transitionSidebar: true,
      displayNotice: false,
    }
  },
  computed: {
    ...mapGetters(['config', 'isTablet', 'openRetailerLayer', 'onlineshopTeaserConfig', 'headerType']),
    showBuyButton() {
      return this.$store.getters.buyButton && this.config.buy.show
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal) {
        const page = this.$router.getPageBySlug(newVal.params.main)
        const showDetail = (newVal.meta && newVal.meta.showDetail) || page.view === 'Detail'
        // eslint-disable-next-line
        this.transitionSidebar = showDetail != this.showDetail
        this.showDetail = showDetail
        this.updateHead()
      },
    },
  },
  created() {
    initTrackingService()
    if (this.isTablet) {
      window.addEventListener('resize', this.displayOrientationNotice)
    }
  },
  mounted() {
    // store initial page slug, shouldn't be counted as page scroll event
    this.$store.commit('addPageScrollSlug', this.$route.params.main)

    this.initBuffer(this.$store.state.cmsContent.pages)
    addLockableSelector('.slide')
    this.displayOrientationNotice()
  },
  destroyed() {
    window.removeEventListener('resize', this.displayOrientationNotice)
  },
  methods: {
    closeDetail() {
      EventBus.$emit('detail-layer-closed')
      const path = this.$route.params.detail
        ? `/${this.$route.params.lang}/${this.$route.params.main}/`
        : `/${this.$route.params.lang}/${this.$router.getDefaultMainSlug()}/`
      this.$router
        .push({
          path: path,
        })
        .catch(() => {})
    },
    backDetail() {
      this.$router.go(-1)
    },
    appearDetail(el) {
      // handle initial state of detail layer
      gsap.set(el, { x: 0 })
      this.afterEnterDetail()
    },
    beforeEnterDetail() {
      this.$nextTick(() => {
        disablePageScroll()
      })
    },
    enterDetail(el, done) {
      gsap.set(el, { x: 0 })
      // disable transition if detail page is requested on initial load
      this.transitionSidebar = this.$route.params.detail ? this.transitionSidebar : false
      if (this.transitionSidebar) {
        gsap.from(el, {
          x: '100%',
          duration: 0.4,
          delay: 0.3,
          ease: 'M0,0 C0.772,0.148 0.79,0.698 1,1 ',
          onComplete: () => {
            done()
          },
        })
      } else {
        gsap.from(el.querySelector('.detail-content'), {
          opacity: 0,
          clearProps: 'opacity',
          duration: 0.5,
          onComplete: () => {
            done()
          },
        })
      }
    },
    afterEnterDetail() {
      // activate close on outside layer click after transition
      this.$refs.main.addEventListener('click', this.closeDetail)
      addScrollableSelector('.detail-layer, .slide, .klaro .cookie-modal .cm-modal.cm-klaro')
      EventBus.$emit('resize-end')
      this.pauseBuffering = true
    },
    leaveDetail(el, done) {
      gsap.set(el, { x: 0 })
      if (this.transitionSidebar) {
        gsap.to(el, {
          x: '100%',
          duration: 0.5,
          ease: 'M0,0 C0.772,0.148 0.79,0.698 1,1 ',
          onComplete: () => {
            done()
          },
        })
      } else {
        gsap.to(el.querySelector('.detail-content'), {
          opacity: 0,
          clearProps: 'opacity',
          duration: 0.5,
          onComplete: () => {
            done()
          },
        })
      }
      // deactivate close on outside layer click
      this.$refs.main.removeEventListener('click', this.closeDetail)
    },
    afterLeaveDetail() {
      gsap.delayedCall(0.2, () => {
        enablePageScroll()
        this.pauseBuffering = false
        EventBus.$emit('resize-end')
      })
    },
    displayOrientationNotice() {
      if (this.isTablet && window.matchMedia('(orientation: portrait)').matches) {
        window.removeEventListener('resize', this.displayOrientationNotice)
        this.displayNotice = true
        setTimeout(() => {
          this.displayNotice = false
        }, 3000)
      } else {
        this.displayNotice = false
      }
    },
    updateHead() {
      if (!this.metaDescriptionElement) {
        this.metaDescriptionElement = document.querySelector('meta[name="description"]')
        this.defaultDescription = this.metaDescriptionElement.getAttribute('content')
      }
      if (!this.documentTitleElement) {
        this.documentTitleElement = document.querySelector('title')
        this.defaultTitle = this.documentTitleElement.textContent
      }
      const page = this.$router.getPageBySlug(this.$route.params.detail || this.$route.params.main)
      this.metaDescriptionElement.content = page.description || this.defaultDescription
      this.documentTitleElement.textContent = page.title || this.defaultTitle

      this.updateCanonicalTags()
    },
    updateCanonicalTags() {
      // remove previous set canonical information
      document.querySelectorAll('link[rel="canonical"]').forEach((element) => element.remove())
      // creates a canonical link tag and dynamically builds the URL
      const linkTag = document.createElement('link')
      linkTag.setAttribute('rel', 'canonical')
      linkTag.href = `${window.location.origin}/${this.$route.params.lang}/${this.$route.params.detail || this.$route.params.main}`
      document.head.appendChild(linkTag)
    },
  },
}
</script>

<style lang="scss">
html,
body {
  overscroll-behavior-y: none;
}

#app {
  display: flex;
  flex-direction: column;
  height: auto;
  position: relative;

  &:before {
    content: '';
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: $black;
    opacity: 0;
    transition: opacity 600ms cubic-bezier(0.39, 0.575, 0.565, 1);
    transition-delay: 150ms;
    z-index: $zindex-main-darker;
    pointer-events: none;
  }

  .orientation-notice {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: $zindex-orientation-notice;

    &::after {
      content: '';
      position: relative;
      display: block;
      width: 100vw;
      height: 100vh;
      background-color: $black;
      opacity: 0.5;
    }

    .notice {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 150px;
      height: 150px;
      z-index: 2;

      svg {
        position: relative;
        z-index: 2;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 250px;
        height: 250px;
        background-color: $black;
        border-radius: 100%;
        opacity: 0.8;
        z-index: 1;
      }
    }
  }

  .main {
    display: flex;
    flex: 1;
    position: relative;
    transition: filter 400ms;
  }

  &.show-detail {
    &:before {
      opacity: 0.8;
    }
  }

  .main-fade-leave-active,
  .main-fade-enter-active {
    transition: all 1s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .main-fade-enter,
  .main-fade-leave-to {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.header-background {
  position: fixed;
  width: calc(100% - var(--wmf-slide-scroll-bar-width));
  height: calc(150px + var(--wmf-onlineshop-teaser-height));
  left: 0;
  top: 0;
  background: linear-gradient(#000000 20%, transparent 100%);
  pointer-events: none;
  z-index: $zindex-header - 1;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-buy-enter-active {
  transition: opacity 0.3s ease 1500ms;
}

.fade-buy-leave-active {
  transition: opacity 0.3s;
}

.fade-buy-enter,
.fade-buy-leave-to {
  opacity: 0;
}

.orientation-enter-active {
  transition: opacity 2s;
}

.orientation-leave-active {
  transition: opacity 0.5s;
}

.orientation-enter {
  opacity: 0;
}

.orientation-enter-to {
  opacity: 1;
}

.orientation-leave {
  opacity: 1;
}

.orientation-leave-to {
  opacity: 0;
}

.fadeLightbox-enter-active,
.fadeLightbox-leave-active {
  transition: opacity 0.6s;
}

.fadeLightbox-enter,
.fadeLightbox-leave-to {
  opacity: 0;
}
</style>
<style lang="scss" scoped>
.buy-button {
  position: fixed;
  z-index: $zindex-buy-button;
  right: 26px;
  top: calc(34px + var(--wmf-content-offset-y));
  display: none;

  @include media-breakpoint-up(lg) {
    position: absolute;
    display: block;
    top: 50%;
    transform: translateY(-50%);
  }

  .simple-header & {
    right: 45px;
  }
}
</style>
