<template>
  <svg :width="widthEm" :height="heightEm" :viewBox="`0 0 ${width} ${height}`" xmlns="http://www.w3.org/2000/svg" aria-labelledby="arrow-right-large">
    <g transform="matrix(1, 0, 0, 1, 134.992996, 0)">
      <g id="Ellipse_113" data-name="Ellipse 113" fill="none" stroke="#fff" stroke-width="1.5">
        <circle cx="44" cy="44" r="44" stroke="none"></circle>
        <circle cx="44" cy="44" r="43.25" fill="none"></circle>
      </g>
      <path
        id="icons8-instagram"
        d="M18.417,5A13.443,13.443,0,0,0,5,18.417v17.5A13.444,13.444,0,0,0,18.417,49.333h17.5A13.444,13.444,0,0,0,49.333,35.916v-17.5A13.444,13.444,0,0,0,35.916,5Zm0,3.5h17.5a9.891,9.891,0,0,1,9.917,9.917v17.5a9.892,9.892,0,0,1-9.917,9.917h-17.5A9.891,9.891,0,0,1,8.5,35.916v-17.5A9.89,9.89,0,0,1,18.417,8.5Zm20.416,4.667A2.333,2.333,0,1,0,41.166,15.5,2.333,2.333,0,0,0,38.833,13.167ZM27.166,15.5A11.667,11.667,0,1,0,38.833,27.166,11.694,11.694,0,0,0,27.166,15.5Zm0,3.5A8.167,8.167,0,1,1,19,27.166,8.141,8.141,0,0,1,27.166,19Z"
        transform="translate(17 17)"
        fill="#fff"
      ></path>
    </g>
    <g transform="matrix(1, 0, 0, 1, -451.5, -1836.691162)">
      <path
        id="Pfad_436"
        data-name="Pfad 436"
        d="M2872.086,3533.277l8.267,8.267-8.267,8.267"
        transform="translate(-2316.164 -1660.853)"
        fill="none"
        stroke="#fff"
        stroke-miterlimit="10"
        stroke-width="1.5"
      ></path>
      <line id="Linie_44" data-name="Linie 44" x1="112.993" transform="translate(451.5 1880.5)" fill="none" stroke="#fff" stroke-width="1.5"></line>
    </g>
  </svg>
</template>

<script>
import IconBase from '@/components/icons/IconBase'

export default {
  name: 'IconClose',
  extends: IconBase,
  props: {
    width: {
      type: [Number],
      default: 222.993,
    },
    height: {
      type: [Number],
      default: 88,
    },
  },
}
</script>

<style scoped></style>
