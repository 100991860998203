<template>
  <component :is="`Icon${name}`" v-bind="$attrs" />
</template>

<script>
// import IconArrowRightLarge from '@/components/icons/IconArrowRightLarge'
// import IconArrowUp from '@/components/icons/IconArrowUp'
// import IconArrowPrev from '@/components/icons/IconArrowPrev'
// import IconArrowNext from '@/components/icons/IconArrowNext'
// import IconArrowSmall from '@/components/icons/IconArrowSmall'
// import IconClose from '@/components/icons/IconClose'
// import IconDownload from '@/components/icons/IconDownload'
// import IconLogo from '@/components/icons/IconLogo'
// import IconPlay from '@/components/icons/IconPlay'
// import IconPlus from '@/components/icons/IconPlus'
// import IconFacebook from '@/components/icons/IconFacebook'
// import IconYoutube from '@/components/icons/IconYoutube'
// import IconInstagram from '@/components/icons/IconInstagram'
// import IconPinterest from '@/components/icons/IconPinterest'
// import IconArrowDown from '@/components/icons/IconArrowDown'
// import IconDots from '@/components/icons/IconDots'
// import IconLanguage from '@/components/icons/IconLanguage'
// import IconMachine600 from '@/components/icons/IconMachine600'
// import IconMachine700 from '@/components/icons/IconMachine700'
// import IconMachine800 from '@/components/icons/IconMachine800'
// import IconMinimize from '@/components/icons/IconMinimize'
// import IconBuy from '@/components/icons/IconBuy'
// import IconBuyCta from '@/components/icons/IconBuyCta'
// import IconCheck from '@/components/icons/IconCheck'
// import IconZoom from '@/components/icons/IconZoom'
// import IconBurger from '@/components/icons/IconBurger'
// import IconCheckmark from '@/components/icons/IconCheckmark'
// import IconInfo from '@/components/icons/IconInfo'
// import IconVideoPlayCircle from '@/components/icons/IconVideoPlayCircle'

export default {
  props: {
    name: {
      type: String,
      default: 'Logo',
    },
  },
  components: {
    IconArrowRightLarge: () => import('@/components/icons/IconArrowRightLarge'),
    IconArrowPrev: () => import('@/components/icons/IconArrowPrev'),
    IconArrowNext: () => import('@/components/icons/IconArrowNext'),
    IconArrowUp: () => import('@/components/icons/IconArrowUp'),
    IconClose: () => import('@/components/icons/IconClose'),
    IconDownload: () => import('@/components/icons/IconDownload'),
    IconLogo: () => import('@/components/icons/IconLogo'),
    IconPlay: () => import('@/components/icons/IconPlay'),
    IconPlus: () => import('@/components/icons/IconPlus'),
    IconFacebook: () => import('@/components/icons/IconFacebook'),
    IconYoutube: () => import('@/components/icons/IconYoutube'),
    IconInstagram: () => import('@/components/icons/IconInstagram'),
    IconPinterest: () => import('@/components/icons/IconPinterest'),
    IconArrowDown: () => import('@/components/icons/IconArrowDown'),
    IconDots: () => import('@/components/icons/IconDots'),
    IconLanguage: () => import('@/components/icons/IconLanguage'),
    IconMachine600: () => import('@/components/icons/IconMachine600'),
    IconMachine700: () => import('@/components/icons/IconMachine700'),
    IconMachine800: () => import('@/components/icons/IconMachine800'),
    IconMinimize: () => import('@/components/icons/IconMinimize'),
    IconBuy: () => import('@/components/icons/IconBuy'),
    IconCheck: () => import('@/components/icons/IconCheck'),
    IconZoom: () => import('@/components/icons/IconZoom'),
    IconCheckmark: () => import('@/components/icons/IconCheckmark'),
    IconBuyCta: () => import('@/components/icons/IconBuyCta'),
    IconInfo: () => import('@/components/icons/IconInfo'),
    IconVideoPlayCircle: () => import('@/components/icons/IconVideoPlayCircle'),
    IconBurger: () => import('@/components/icons/IconBurger'),
    IconArrowSmall: () => import('@/components/icons/IconArrowSmall'),
    IconQuote: () => import('@/components/icons/IconQuote'),
    IconAR: () => import('@/components/icons/IconAR'),
  },
}
</script>
