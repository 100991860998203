const debounce = require('lodash/debounce')
import EventBus from '@/helpers/eventBus.js'

export default {
    methods: {
        initResizeHandler() {
            const handleResizeEnd = (event) => {
                EventBus.$emit('resize-end', event)
            }
            this.debouncedHandleResizeEnd = debounce(handleResizeEnd, 250)

            window.addEventListener(
                'resize',
                (event) => {
                    EventBus.$emit('resize', event)
                    this.debouncedHandleResizeEnd(event)
                },
                false
            )
        },
    },
    destroy() {
        window.removeEventListener('resize', this.debouncedHandleResizeEnd)
    },
    mounted() {
        this.initResizeHandler()
    },
}
