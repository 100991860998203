<template>
  <div
    class="animation"
    @click="skipAnimation"
    :class="{
      'animation--full-video': fullVideo,
      'animation--skipped': skip || contentVisible,
      'is-tablet': isTablet,
    }"
  >
    <div class="video" v-if="video && !videoDone" :style="{ top: videoPosition }">
      <video autoplay playsinline muted preload="auto" ref="video" @ended.once="onVideoEnded" @canplaythrough="onVideoReady">
        <source :src="videoSrc" type="video/mp4" />
      </video>

      <BaseImage class="poster" ref="poster" v-if="video.poster" v-bind="video.poster" @load="onPosterLoaded"></BaseImage>

      <div ref="buffering" class="buffering">
        <IconBuffering />
      </div>
    </div>

    <div
      class="wrapper"
      :class="[
        contentItem.copy || contentHasTeaser(contentItem) ? 'has-copy' : '',
        contentItem.slider ? 'clear-margin' : '',
        contentItem.align,
        contentItem.position,
        showEditorialContent(index) ? 'has-editorial-content' : '',
        contentItem['column-direction'],
        {
          'wrapper--flex-start': showEditorialContent(index) || getCollapseContent(contentItem),
        },
      ]"
      v-for="(contentItem, index) in content"
      :key="`content-animation-item-${index}`"
      ref="item"
    >
      <ScrollButton
        v-if="next && (videoDone || isMobile)"
        class="scrollButton"
        @click.native="onScrollButtonClick"
        :label="$t('scroll')"
      ></ScrollButton>
      <!--      <div :class="{ 'intro-content': showEditorialContent(index) }">-->
      <div class="intro-content">
        <div
          class="chapter-wrapper container-fluid"
          :class="{
            ['chapter--flex' + getChapterFlex(contentItem)]: getChapterFlex(contentItem),
            ['outline--lines' + getOutlineLines(contentItem)]: getOutlineLines(contentItem),
          }"
          v-if="contentItem.outline || contentItem.chapter"
        >
          <div class="row">
            <OutlineText v-if="contentItem.outline" class="outline" ref="chapter_outline">
              {{ contentItem.outline }}
            </OutlineText>
            <h2
              class="indent chapter"
              v-if="contentItem.chapter"
              :class="{
                ['chapter--align-' + contentItem['chapter-align']]: contentItem['chapter-align'],
                ['indent--' + contentItem['chapter-indent']]: contentItem['chapter-indent'],
              }"
              ref="chapter_headline"
              v-html="contentItem.chapter"
            ></h2>
          </div>
        </div>
        <div
          class="content-media"
          ref="media"
          :class="{
            'content-media--hidden': !mediaVisible,
            'content-media--full': contentItem.slider,
          }"
        >
          <div
            v-if="contentItem['background']"
            class="background"
            :style="backgroundStyles(contentItem)"
            :class="{
              'content-media--background': contentItem['background'],
            }"
          ></div>
          <template v-if="contentItem.imageMobile && isMobile">
            <BaseImage class="image" v-bind="contentItem.imageMobile" />
          </template>

          <template v-else>
            <BaseImage class="image" v-if="contentItem.image && contentItem.image.src" v-bind="contentItem.image" />
          </template>
        </div>
        <div class="container" style="width: 100vw" v-if="contentItem.slider">
          <div class="product-slider__container row">
            <ProductSlider
              class="col-10 offset-1 col-lg-8 offset-lg-2 col-xxl-10 offset-xxl-1"
              :contents="sliderContents(contentItem.slider.contents)"
              @change="onProductSliderChange"
              :mouseEnabled="sliderEnabled"
              :visible="contentVisible"
            />

            <div class="product-slider__info-container col-12 offset-0 col-lg-10 offset-lg-1">
              <!--needs a duplicate for desktop-->
              <p class="copy copy--regular" v-if="contentItem.copy" v-html="contentItem.copy"></p>

              <ProductSliderInfo
                v-if="sliderContents(contentItem.slider.contents)[productSliderIndex] && contentVisible"
                :content="sliderContents(contentItem.slider.contents)[productSliderIndex].detail"
              />
            </div>
          </div>
        </div>

        <div class="container-fluid content-container">
          <div class="row">
            <div
              class="indent content"
              :style="{
                transform: 'translateY(' + contentItem['content-position'] + 'vh)',
                textAlign: contentItem['content-textalign'],
              }"
              :class="{
                ['indent--' + contentItem['content-indent']]: Number.isInteger(contentItem['content-indent']),
                'content--narrow': contentItem.slider,
                'content--has-navigation': subpages && navigation === 'inline',
                'content-only-mobile': contentItem['content-only-mobile'],
              }"
            >
              <component :is="index < content.length - 1 ? 'h3' : 'h1'" class="headline headline--regular" v-if="contentItem.headline">
                <span v-for="(textElement, index) in contentItem.headline" v-html="textElement" :key="`text-${textElement}-${index}`"></span>
              </component>

              <SubpageNavigation v-if="subpages && navigation === 'inline'" v-bind="subpages" :inline="navigation === 'inline'"></SubpageNavigation>
              <p class="copy copy--regular" v-if="contentItem.copy" v-html="contentItem.copy"></p>

              <div class="teaser-wrapper" v-if="contentHasTeaser(contentItem)">
                <template v-for="(teaser, index) in [contentItem['teaser'], contentItem['teaser-2']]">
                  <div class="teaser" v-if="teaser && teaser.headline && teaser.image" :key="`teaser-${index}`">
                    <ImageTeaser class="image-teaser" v-bind="teaser" />
                  </div>

                  <div class="teaser" v-else-if="teaser && teaser.headline" :key="`teaser-${index}`">
                    <TextTeaser class="text-teaser" v-bind="teaser" />
                  </div>
                </template>
              </div>

              <MoreButton class="cta" v-if="contentItem.cta" v-bind="contentItem.cta">
                {{ contentItem.cta.label }}
              </MoreButton>
            </div>
          </div>
        </div>
      </div>

      <div v-if="showEditorialContent(index)" class="editorial-content" ref="editorial">
        <component
          v-for="(module, index) in editorial_content"
          :key="`module-${module.type}-${index}`"
          :is="module.type"
          v-bind="module.props"
        ></component>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { gsap } from 'gsap'
import EventBus from './../../helpers/eventBus.js'
import BaseImage from '@/components/BaseImage'
import IconBuffering from '@/components/icons/IconBuffering'
import MoreButton from '@/components/buttons/MoreButton'
import OutlineText from '@/components/OutlineText'
import ScrollButton from '@/components/buttons/ScrollButton.vue'
import Cta from '@/components/main/editorial-modules/Cta.vue'

export default {
  name: 'Animation',
  props: {
    video: Object,
    subpages: Object,
    content: {
      type: [Array],
      default: () => [],
    },
    editorial_content: {
      type: [Array],
      default: () => [],
    },
    navigation: String,
    fullVideo: Boolean,
    next: String,
  },
  data() {
    return {
      skip: false,
      productSliderIndex: -1,
      contentVisible: false,
      videoDone: false,
      videoPosition: '0',
      isMobile: false,
      mediaVisible: false,
      sliderEnabled: false,
      isBuffering: undefined,
      debugTimer: undefined,
    }
  },
  components: {
    BaseImage,
    MoreButton,
    OutlineText,
    ScrollButton,
    Cta,
    IconBuffering,
    ProductSlider: () => import('@/components/ProductSlider'),
    Events: () => import('@/components/main/editorial-modules/Events.vue'),
    ProductSliderInfo: () => import('@/components/ProductSliderInfo'),
    SubpageNavigation: () => import('@/components/main/SubpageNavigation.vue'),
    Downloads: () => import('@/components/main/editorial-modules/downloads/Downloads.vue'),
    Summary: () => import('@/components/main/editorial-modules/Summary.vue'),
    TextTeaser: () => import('@/components/TextTeaser'),
    ImageTeaser: () => import('@/components/ImageTeaser'),
    TeaserSlider: () => import('@/components/main/editorial-modules/teaser-slider/TeaserSlider'),
    EditorialHeadline: () => import('@/components/main/editorial-modules/EditorialHeadline'),
    Banner: () => import('@/components/main/editorial-modules/Banner'),
  },

  mounted() {
    this.skip = this.skipVideo ? true : false

    // skip already played video transitions
    if (this.video && this.video.src) {
      if (this.skipVideo) {
        this.skip = this.playedVideoTransitions.indexOf(this.video.src) > -1
      }
      this.$store.commit('addPlayedVideoTransition', this.video.src)
    }

    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
      this.onResize(null, true)

      this.initAnimationTimeline()

      if (this.skip) {
        this.tl.seek('last')
        this.tl.play()

        this.videoDone = true
        this.mediaVisible = true
      } else if (this.$refs.video) {
        /*pausing buffer helper to save ressources*/
        this.$store.commit('setCurrentBufferVideo', this.video.src)
        this.isBuffering = true
      } else {
        this.mediaVisible = true
        this.contentVisible = true
      }

      this.$store.commit('skipVideo', true)
    })
  },
  destroyed() {
    if (this.tl) this.tl.kill()
    this.tl = null

    this.$store.commit('setCurrentBufferVideo', undefined)
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    ...mapGetters(['skipVideo', 'playedVideoTransitions', 'contentMediaPosition', 'isTablet']),

    videoSrc() {
      if (window.innerWidth < 992) {
        return this.video.src.replace('assets/videos/', 'assets/videos/small/')
      }
      return this.video.src
    },

    hasSlider() {
      return this.content.find((el) => el.slider !== undefined)
    },
  },
  watch: {
    isBuffering() {
      if (this.$refs.buffering) {
        if (this.isBuffering) {
          gsap.to(this.$refs.buffering, { autoAlpha: 1, delay: 2 })
        } else {
          gsap.to(this.$refs.buffering, {
            overwrite: true,
            autoAlpha: 0,
          })
        }
      }
    },
    videoDone() {
      this.contentVisible = true
      this.mediaVisible = true
      this.sliderEnabled = true
    },
  },
  methods: {
    onScrollButtonClick() {
      this.$store.commit('skipVideo', false)
      gsap.delayedCall(0.2, () => {
        // reset scrolling on iOS
        document.documentElement.scrollTo(0, 0)
        window.scrollTo(0, 0)
      })
      this.$store.commit('incPageScrollCounter', this.next)
      this.$router.push({
        path: `/${this.$route.params.lang}/${this.next}/`,
        query: this.$route.query,
      })
    },
    showEditorialContent(index) {
      return this.editorial_content.length > 0 && this.content.length === index + 1
    },
    onResize(e, skipDelay) {
      this.isMobile = window.innerWidth < 992

      let delay = !skipDelay ? 150 : 0
      if (this.isMobile && this.$refs.media.length) {
        setTimeout(() => {
          if (!this.$refs.media[this.$refs.media.length - 1]) return

          const contentMediaPosition = {
            top: this.$refs.media[this.$refs.media.length - 1].offsetTop,
            height: this.$refs.media[this.$refs.media.length - 1].clientHeight,
          }

          this.$store.commit('setContentMediaPosition', contentMediaPosition)

          if (this.$refs.media.length === 1 && this.hasSlider) {
            const headlineBounds = this.$el.getElementsByClassName('chapter-wrapper')[0].getBoundingClientRect()

            const pos = headlineBounds.y + headlineBounds.height

            this.videoPosition = Math.round(pos) + 'px'
          } else {
            this.videoPosition = Math.round(contentMediaPosition.top) + 'px'
          }
        }, delay)
      } else {
        this.$store.commit('setContentMediaPosition', null)
        this.videoPosition = '0'
      }
    },
    getCollapseContent(contentItem) {
      return !contentItem.copy && !contentItem.cta && contentItem.outline
    },
    getChapterFlex(item) {
      let flex = false
      const align = item['chapter-align']

      if (align) {
        if (align.split(' ')[1]) flex = align.split(' ')[1]
      }
      return flex
    },
    getOutlineLines(item) {
      const match = item.outline ? item.outline.match(/###/g) : []
      return match ? `--${match.length + 1}` : null
    },
    onPosterLoaded() {
      this.onResize()
    },
    onVideoReady() {
      this.isBuffering = false

      this.$nextTick(() => {
        if (this.$refs.poster) {
          //this.mediaVisible = true
          this.$refs.video.play()

          gsap.set(this.$refs.video, {
            opacity: 1,
          })
          //this.$store.commit('setCurrentBufferVideo', undefined)

          gsap.to(this.$refs.poster.$el, {
            duration: 0.2,
            opacity: 0,
            onComplete: () => {
              if (!this.isMobile) this.contentVisible = true
              this.mediaVisible = true

              if (this.$refs.item.length > 1) {
                this.tl.play()
              }
            },
          })
        }
        this.onResize()
      })
    },
    onVideoEnded() {
      if (this.video) {
        EventBus.$emit('video_buffered', this.video.src)
      }
      if (this.isMobile) {
        gsap.to(this.$refs.video, {
          opacity: 0,
          duration: 1,
          onComplete: () => {
            this.videoDone = true
          },
        })
      }

      this.$nextTick(() => {
        if (this.tl) {
          this.skip = true
          this.isMobile && !this.fullVideo ? this.tl.play() : this.tl.play('last')
        }
        // fix rendering issue which causes flicker of video in firefox mobile
        if (this.isMobile && window.scrollY === 0) {
          window.scroll(0, 0)
        }
      })
    },
    initAnimationTimeline() {
      if (!this.$refs.item) {
        return false
      }

      // pause animation until video is fully loaded an read to play
      this.tl = new gsap.timeline({
        paused: true,
      })

      // item animation
      for (let i = 0; i < this.$refs.item.length; i++) {
        const item = this.$refs.item[i]
        const content = this.content[i]
        const duration = content.duration ? content.duration : 0
        const delay = content.delay ? content.delay : 0
        const lastItem = i < this.$refs.item.length - 1 ? false : true
        if (lastItem) {
          // pause animation until video have reached the end, video will trigger play
          this.tl.addPause()
          this.tl.addLabel('last')
          // hide video and show last content element with still image
          const animationBlock = []
          if (this.$refs.video && !this.isMobile) {
            /*animationBlock.push(
              gsap.to(this.$refs.video, {
                opacity: 0,
                display: 'none',
                duration: 1,
              })
            )*/
          }
          animationBlock.push(
            gsap.to(item, {
              display: 'flex',
              opacity: 1,
              duration: 1,
              delay: delay,
            })
          )
          this.tl.add(animationBlock)

          if (this.$refs.editorial) {
            animationBlock.push(
              gsap.to(this.$refs.editorial, {
                opacity: 1,
                duration: 1,
                pointerEvents: 'auto',
                delay: delay,
              })
            )
          }

          this.tl.add(animationBlock)
        } else {
          this.tl.to(item, {
            display: 'flex',
            opacity: 1,
            duration: 0,
            delay: delay,
          })
        }
        const animationBlock = []
        if (item.querySelectorAll('.headline span').length > 0) {
          // show headline parts
          animationBlock.push(
            gsap.to(item.querySelectorAll('.headline span'), {
              opacity: 1,
              duration: 0.8,
              stagger: 0.7,
            })
          )
        }
        // show content parts
        const contentParts = item.querySelectorAll('.headline, .copy, .teaser, .cta, .image')
        if (contentParts.length > 0) {
          animationBlock.push(
            gsap.to(contentParts, {
              opacity: 1,
              duration: 1,
              stagger: 0.1,
            })
          )
        }

        this.tl.add(animationBlock)

        // hide content element, the last element have to stay visible
        if (!lastItem) {
          if (item.querySelectorAll('.headline, .copy, .teaser, .cta, .image').length > 0) {
            this.tl.to(
              item.querySelectorAll('.headline, .copy, .teaser, .cta, .image'),
              {
                opacity: 0,
                duration: 1,
              },
              `+=${duration}`
            )
            this.tl.to(item, {
              display: 'none',
            })
          }
        }
      }

      this.fadeHeadlines()

      this.tl.call(
        () => {
          this.$emit('content-visible')
          this.onResize()
        },
        null,
        'last'
      )

      this.tl.call(() => {
        if (!this.isMobile) this.videoDone = true
      })

      if (this.$refs.video) {
        this.$refs.video.pause()
      } else {
        this.tl.play()
      }

      // play timeline instant on mobile viewport
      if (this.isMobile && !this.fullVideo) {
        this.tl.play('last')
        this.isBuffering = false
      }
    },
    initAppearSlide(el, offset) {
      this.tl.add(
        gsap.set(el, {
          autoAlpha: 0,
          x: offset,
        }),
        0
      )

      this.tl.add(gsap.to(el, { autoAlpha: 1 }), 0.75)
      this.tl.add(
        gsap.to(el, {
          ease: 'Expo.easeOut',
          x: 0,
          duration: 2,
        }),
        0.75
      )
    },
    fadeHeadlines() {
      if (this.$refs.chapter_headline) {
        this.initAppearSlide(this.$refs.chapter_headline, 20)
      }
      if (this.$refs.chapter_outline) {
        this.initAppearSlide(this.$refs.chapter_outline[0].$el, -30)
      }
    },
    skipAnimation() {
      this.skip = true

      if (this.tl) {
        this.tl.seek(this.tl.duration(), false)
        this.videoDone = true
      }
      if (this.$refs.video) {
        this.$store.commit('setCurrentBufferVideo', undefined)

        /*stopping load of skipped video*/
        this.$refs.video.pause()
        this.$refs.video.removeAttribute('src') // empty source
        this.$refs.video.load()
      }
    },
    onProductSliderChange(index) {
      this.productSliderIndex = index
    },
    sliderContents(contents) {
      // ensure that at least 5 content items displayed
      // dublicate items if less than 5
      if (contents.length > 5) {
        return contents
      } else if (contents.length > 3) {
        return contents.concat(contents)
      } else {
        return contents.concat(contents).concat(contents)
      }
    },
    backgroundStyles(content) {
      if (content['background']) {
        return `background-image:url(/${content['background']});`
      }
      return ''
    },
    contentHasTeaser(contentItem) {
      return (
        (contentItem['teaser'] && {}.hasOwnProperty.call(contentItem['teaser'], 'href')) ||
        (contentItem['teaser-2'] && {}.hasOwnProperty.call(contentItem['teaser-2'], 'href'))
      )
    },
  },
}
</script>

<style scoped lang="scss">
@mixin indent($side, $amount) {
  //12-col-grid
  margin-#{$side}: calc(100% / (12 / #{$amount}) + 20px);
}

.animation {
  width: 100%;
  height: auto;
  padding-top: var(--wmf-content-offset-y);

  position: relative;
  //max-height: 100vh;
  overflow: hidden;

  // better mobile scroll behavior
  padding-bottom: 100px;

  .has-footer & {
    padding-bottom: 0;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: 0px;
  }

  .video {
    position: absolute;
    width: 100vw;
    height: 0;
    padding-top: math.div(100vw, math.div(16, 9));

    video {
      opacity: 0;
    }

    .buffering {
      position: absolute;
      width: 30px;
      height: 30px;

      left: 50%;
      top: 48%;

      transform: translateX(-50%) translateY(-50%);

      z-index: 10;
      opacity: 0;
      visibility: hidden;
    }

    z-index: 2;
  }

  .poster,
  video {
    position: absolute;
    top: var(--wmf-content-offset-y);
    left: 0;
    width: 100%;
    height: auto;
  }

  .poster {
    padding-top: 0 !important;
    height: 100%;
  }

  @include media-breakpoint-up(lg) {
    .video {
      z-index: 0;
    }

    .poster,
    video {
      position: absolute;
      //top: 0;
      top: var(--wmf-content-offset-y);
      left: 0;
      width: 100%;
      height: 100%;
      min-height: 414px;
      object-fit: cover;
    }

    .poster {
      min-height: 100%;
      padding-top: 0 !important;
    }
  }

  &--full-video {
    .video {
      position: absolute;
      top: 0 !important;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .poster,
    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      object-fit: cover;

      //@include media-breakpoint-down(lg) {
      //width: 170%;
      //left: -35%;
      //object-fit: cover;
      //}
    }

    .poster {
      min-height: 100%;
      padding-top: 0 !important;
    }

    .chapter-wrapper {
      z-index: 0;
    }
  }
}

.wrapper {
  position: absolute;
  opacity: 0;
  display: none;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  height: 100%;
  max-height: 100vh;
  min-height: 414px;
  width: 100%;
  //width: 100vw;

  &:last-child {
    display: flex;
    position: relative;
    height: auto;
    max-height: none;
    //min-height: 100vh;
    min-height: calc(100vh - var(--wmf-content-offset-y));
  }

  /*.indent {
        padding-right: 0;
    padding-left: 0;
    margin-left: 0;
  }*/

  .content-container .row {
    flex-direction: row;
  }

  @include media-breakpoint-up(xl) {
    .indent,
    .indent--1 {
      @include indent('left', 1);
    }

    .indent--2 {
      @include indent('left', 2);
    }
  }

  &.center {
    justify-content: flex-start;

    @include media-breakpoint-up(sm) {
      justify-content: center;

      .content-container .row {
        justify-content: center;
      }
      .content {
        width: auto;
      }
    }

    @include media-breakpoint-up(lg) {
      .content {
        margin-top: 205px;
      }
    }
  }

  &.bottom-left-indent {
    align-items: flex-start;
    justify-content: flex-start;

    @include media-breakpoint-up(lg) {
      justify-content: space-between;
    }

    .chapter-wrapper {
      display: flex;
      justify-content: flex-start;
    }
  }

  &.bottom-right-indent {
    align-items: flex-end;
    justify-content: flex-start;

    @include media-breakpoint-up(lg) {
      justify-content: space-between;
    }

    .container-fluid .row {
      width: 100%;
      position: relative;
      justify-content: flex-start;

      @include media-breakpoint-up(lg) {
        justify-content: flex-end;
      }
    }

    .indent {
      margin-left: 0;
    }

    .chapter-wrapper {
      display: flex;
      justify-content: flex-start;

      .chapter {
        max-width: 560px;
        width: 100%;
      }
    }

    @include media-breakpoint-up(lg) {
      .chapter-wrapper {
        justify-content: flex-end;
      }

      .indent,
      .indent--1 {
        width: auto;
        left: unset;
        padding-left: 0;
        padding-right: 0;
        @include indent('right', 1);
      }

      .indent--0 {
        width: 25vw;
        left: unset;
        padding-left: 0;
        padding-right: 0;
        margin-right: 0;
      }

      .indent--2 {
        @include indent('right', 2);
      }
    }

    @include media-breakpoint-up(xl) {
      .indent--0 {
        margin-right: 60px;
      }
    }
  }

  &.reverse {
    @include media-breakpoint-down(lg) {
      .content {
        transform: none !important;
      }
    }

    @include media-breakpoint-up(lg) {
      flex-direction: column-reverse;

      .chapter-wrapper {
        margin-top: 0;
        margin-bottom: 10vh;
      }

      .content {
        margin-top: 110px;
        padding-bottom: 0;
      }
    }
  }

  .chapter {
    order: 0;
  }

  .image {
    order: 1;
  }

  .content-container {
    order: 2;

    @include media-breakpoint-down(lg) {
      padding-right: var(--wmf-container-padding-x);
      padding-left: var(--wmf-container-padding-x);

      .row {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }

  &--flex-start {
    justify-content: flex-start !important;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //padding: 0 var(--wmf-container-padding-x) 0 100px;
  padding: 0;
  z-index: 1;
  position: relative;
  width: auto;

  &--narrow {
    //width: 100%;
    align-self: flex-start;

    .copy {
      padding-top: 30px;
    }

    @include media-breakpoint-up(xxxl) {
      display: none;
    }
  }

  &--has-navigation {
    padding-left: 0px !important;
    padding-right: 0px !important;

    @include media-breakpoint-up(md) {
      padding-left: 40px !important;
      padding-right: 40px !important;
    }

    @include media-breakpoint-up(lg) {
      padding-left: 0 !important;
      max-width: 25vw;
    }
  }
}

.intro-content {
  width: 100vw;
  display: flex;
  flex-direction: column;

  &.has-copy & {
    z-index: 1;
  }

  .has-editorial-content.has-copy & {
    @include media-breakpoint-up(lg) {
      min-height: calc(100vh - var(--wmf-content-offset-y));
    }
  }
}

.chapter-wrapper {
  position: relative;
  width: 100%;
  left: 0;
  margin-top: 125px;
  z-index: 2;

  &.outline--lines--2,
  .clear-margin & {
    margin-bottom: 0 !important;
  }

  @include media-breakpoint-up(lg) {
    .has-copy & {
      margin-bottom: 50px;
    }
  }

  @include media-breakpoint-up(xl) {
    margin-top: 110px;
    .has-copy & {
      margin-bottom: 20vh;
    }
  }

  @media screen and (min-width: 1200px) and (max-height: 800px) {
    .has-copy & {
      margin-bottom: 10vh;
    }
  }

  @media screen and (min-width: 1200px) and (max-height: 700px) {
    .has-copy & {
      margin-bottom: 5vh;
    }
  }

  .row {
    position: relative;
  }

  &.chapter--flexleft {
    .row {
      justify-content: flex-start !important;
    }

    .indent {
      margin-right: 0;
      @include media-breakpoint-up(xl) {
        @include indent('left', 1);
      }
    }

    .indent--2 {
      @include media-breakpoint-up(xl) {
        @include indent('left', 2);
      }
    }
  }
}

.chapter {
  position: relative;
  top: -32px;
  line-height: 1;
  text-transform: uppercase;
  padding: 0;

  &--align-center {
    bottom: unset !important;
    top: calc(50% - 8px) !important;
    transform: translateY(-50%);
    position: absolute;
  }

  @include media-breakpoint-up(md) {
    top: -60px;
  }

  @include media-breakpoint-up(lg) {
    top: unset;
    position: absolute;
    bottom: 2px;
    white-space: nowrap;

    &--align-top {
      bottom: unset;
      top: 0;
    }
  }
}

.outline {
  position: relative;
  left: 0;
  width: auto !important;
}

.headline {
  flex-grow: 0;

  span {
    opacity: 0;
  }
}

.teaser-wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  > * {
    margin-right: 18px;

    &:last-child {
      margin-right: 0;
    }
  }

  & + * {
    margin-top: 26px;
  }
}

.teaser {
  opacity: 0;
  width: 100%;

  @include media-breakpoint-up(md) {
    width: auto;
  }
}

.text-teaser {
  color: rgba(255, 255, 255, 0.8);
  border-color: rgba(255, 255, 255, 0.8);
  transition: color 300ms, border-color 300ms;

  @include media-breakpoint-up(lg) {
    max-width: 400px;
  }

  &:hover {
    color: $white;
    border-color: $white;
  }
}

.image-teaser {
  width: 100%;
  display: block;
  margin-bottom: 20px;

  @include media-breakpoint-up(md) {
    width: 280px;
  }

  @include media-breakpoint-up(xl) {
    width: 220px;
  }

  @include media-breakpoint-up(xxl) {
    width: 294px;
  }
}

.copy,
.cta {
  opacity: 0;
  color: rgba(255, 255, 255, 0.8);
}

@include media-breakpoint-up(lg) {
  .content-only-mobile {
    display: none;
  }
}

.image {
  //checken ob das nich mit irgendwas kollidiert
  @include media-breakpoint-up(lg) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    min-height: 414px;
  }
}

.content-media {
  position: relative;
  width: 100vw;
  height: auto;
  order: 1;
  overflow: hidden;

  @include media-breakpoint-up(lg) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    order: unset;
  }

  &--hidden {
    opacity: 0 !important;
  }

  &--full {
    position: absolute;
    top: 0;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .video,
    .image {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
    }

    @include media-breakpoint-up(xxxl) {
      object-fit: cover;
      height: 100%;
    }
  }

  &--background {
    position: absolute;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-repeat: repeat-y;
    background-size: 100% auto;
  }
}

.has-copy {
  .content-container {
    @include media-breakpoint-up(xl) {
      flex-grow: 1;
    }
  }

  .content {
    padding-top: 26px;
    padding-bottom: 70px;

    @include media-breakpoint-up(lg) {
      padding-bottom: 50px;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200vw;
        z-index: -1;
        pointer-events: none;
        height: 200vh;
        background: radial-gradient(circle at 50% 50%, #00000085 100px, rgb(0 0 0 / 0%) 50vw);
        transform: translateY(-50%) translateX(-50%);
        opacity: 0.8;
      }
    }
  }

  .headline,
  .copy {
    width: 100%;

    @include media-breakpoint-up(lg) {
      max-width: 560px;
    }
  }

  .headline {
    margin-bottom: 18px;
  }

  .copy {
    margin-bottom: 26px;

    ::v-deep p:first-child {
      margin-bottom: 0;
    }
  }

  .chapter {
    width: 100%;
  }
}

.product-slider__info-container {
  position: relative;

  .copy {
    /*copy duplicate for really large desktops only (xxxl - 1500) - needs no indent*/
    padding-right: var(--wmf-container-padding-x);
    padding-left: var(--wmf-container-padding-x);
    padding-top: var(--wmf-container-padding-x);

    display: none;
  }

  @include media-breakpoint-up(xxxl) {
    //padding-bottom: 240px;

    .copy {
      display: block;
      width: 33%;
      max-width: unset;
      margin-top: 30px;
    }

    .product-slider-info {
      position: absolute;
      top: 0;
    }
  }
}

.animation--skipped {
  .product-slider__info-container {
    @include media-breakpoint-up(xxxl) {
      padding-bottom: 240px;
    }
  }

  .img {
    max-height: unset;
  }
}

.product-slider__container {
  position: relative;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  z-index: 2;

  display: flex;
  flex-direction: column;
}

.scrollButton {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;

  @include media-breakpoint-up(lg) {
    bottom: 30px;
  }
}

.editorial-content {
  pointer-events: none;
  order: 3;
  opacity: 0;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding-bottom: 60px;
  max-width: 1920px;

  &:last-child {
    padding-bottom: 0;
  }

  > *:first-child {
    margin-top: calc(85px + var(--wmf-onlineshop-teaser-height));

    .has-copy & {
      margin-top: 0;
    }

    @include media-breakpoint-up(lg) {
      margin-top: calc(110px + var(--wmf-onlineshop-teaser-height));

      .has-copy & {
        margin-top: calc(140px + var(--wmf-onlineshop-teaser-height));
      }
    }
  }
}
</style>
