<template>
  <svg :width="widthEm" :height="heightEm" :viewBox="`0 0 ${width} ${height}`" xmlns="http://www.w3.org/2000/svg" aria-labelledby="arrow-right-large">
    <g :transform="short ? 'translate(-227.834 0.75)' : 'translate(-197.834 0.75)'">
      <circle
        cx="45.321"
        cy="45.321"
        r="45.321"
        transform="translate(260.327)"
        fill="none"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-width="1.5"
      />
      <g transform="translate(-253.666 -1835.17)">
        <path
          d="M2872.086,3533.277l8.267,8.267-8.267,8.267"
          transform="translate(-2316.164 -1660.853)"
          fill="none"
          stroke="currentColor"
          stroke-miterlimit="10"
          stroke-width="1.5"
        />
        <line
          :x1="short ? 83 : 112.993"
          :transform="short ? 'translate(483 1880.5)' : 'translate(451.5 1880.5)'"
          fill="none"
          stroke="currentColor"
          stroke-width="1.5"
        />
      </g>
    </g>
  </svg>
</template>

<script>
import IconBase from '@/components/icons/IconBase'

export default {
  name: 'IconClose',
  extends: IconBase,
  props: {
    width: {
      type: [Number],
      default: 154,
    },
    height: {
      type: [Number],
      default: 92,
    },
    short: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped></style>
